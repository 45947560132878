export default {
    addnew: {
		messageNotice: "Dear customer, hello! Please check your new information.",
		messages: "My Message",
		completed: "Завершено",
		position: "На складе",
		all: "полный",
		submit: "представить",
        realFirst: "Пожалуйста, сначала проверьте свою личность",
        payFirst: "Пожалуйста, сначала установите пароль для вывода средств",
        password1: "Изменить пароль",
        password2: "Изменить пароль",
        paypassTit1: "Пожалуйста, установите пароль для вывода средств",
        oldPass: "Введите оригинальный пароль банкомата",
        allView: "Читать полностью",
        confirmOrder: "Подтвердите заказ",
        name: "имя",
        fee: "комиссия за обработку",
        orderCycle: "Выберите время",
        loan: "получить кредит ",
        loanTit: "студенческий кредит",
        loanHostory: "Кредитные записи",
        labelProductTitle: "Название продукта",
        immediateRepayment: "Немедленное погашение",
        loadAmount: "Ожидаемая сумма кредита",
        RepaymentCycle: "Цикл погашения кредита",
        day: "день",
        dailyRate: "ежедневная процентная ставка",
        repaymentMethod: "способ погашения",
        lendingCompany: "кредитная компания",
        loanTip: "Кредитные займы (пожалуйста, убедитесь, что фотографии хорошо видны)",
        loanTip1: "Загрузите свидетельство о собственности",
        loanTip2: "Подтверждение дохода (трудовые отношения)",
        loanTip3: "Реквизиты банковской выписки",
        loanTip4: "Загрузите лицевую фотографию вашего удостоверения личности",
        interest: "саке",
        repaid: "возвращённый",
        unpaid: "невозврат",
        tab1: "монеты",
        tab2: "договор",
        tab3: "опционы",
        available: "доступный кредит",
        pending: "блокировка",
        equivalent: "сумма до",
        fundTransfer: "Перевод средств",
        flashExchange: "наличные",
        select_breed: "Выберите валюту",
        from: "через ",
        to: "до",
        transferQuantity: "Количество переводов",
        repaymentMethod1:"подлежащие погашению одним платежом"
    },
	addNew: {
		place_card_back: "Нажмите для загрузки обратной фотографии удостоверения личности",
		side1: "Управление заказами",
		side2: "Изменить пароль",
		side3: "Язык",
		side4: "Выход из системы",
		product: "Продукты",
		direction: "Направление",
		cycle: "Период операции",
		long_buy: "Купить больше",
		short_sell: "Пустая продажа",
		open_time: "Время открытия склада",
		close_time: "Время складирования",
		open_price: "Открытая цена",
		close_price: "Складские цены",
		number: "Количество",
		profit_loss: "Прибыль и убыток",
		commission: "Плата за услуги",
		account_type: "Тип операции",
		type1: "Денежный счет",
		type2: "Банковский счет",
		type3: "Счета контрактов",
		type4: "Опционный счет",
		type5: "Финансовые счета",
		message1: "Введите сумму",
		message2: "Недостаточный остаток на счете",
		place_transPsd: "Введите пароль транзакции.",
		countpath: "Конец обратного отсчета автоматически переходит на страницу заказа",
		nowpath: "Прыжок!",
	},
	billTypes: [
		{v: 101, name: 'Заполнение'},
		{v: 102, name: 'Вклад'},
		{v: 103, name: 'Замораживание'},
		{v: 104, name: 'Оттепель'},
		{v: 105, name: 'Вклад'},
		{v: 106, name: 'Вклад'},
		{v: 201, name: 'Введение замораживания'},
		{v: 202, name: 'Показать'},
		{v: 203, name: 'Достигнут успех'},
		{v: 204, name: 'Ошибка вывода'},
		{v: 205, name: 'Сборы за снятие наличных средств'},
		{v: 206, name: 'Вывод'},
		{v: 207, name: 'Переводы'},
		{v: 208, name: 'Денежные переводы'},
		{v: 209, name: 'Денежные переводы'},
		{v: 301, name: 'Денежные сборы'},
		{v: 302, name: 'Доходы от контрактов'},
		{v: 303, name: 'Контрактные убытки'},
		{v: 311, name: 'Приобретение опционов'},
		{v: 312, name: 'Опционная прибыль'},
		{v: 313, name: 'Возврат опциона'},
		{v: 314, name: 'Опционная плата'},
		{v: 315, name: 'Купить замороженные монеты'},
		{v: 316, name: 'Вычет при покупке валюты'},
		{v: 317, name: 'Купить монеты назад'},
		{v: 318, name: 'Купить монеты на счет'},
		{v: 319, name: 'Купить монеты на счет'},
		{v: 320, name: 'Продажа монет заморожена'},
		{v: 321, name: 'Вычет при продаже валюты'},
		{v: 322, name: 'Деньги продаются обратно'},
		{v: 323, name: 'Деньги продаются на счет'},
		{v: 324, name: 'Деньги продаются на счет'},
		{v: 325, name: 'Денежные сборы'},
		{v: 401, name: 'Минералы присоединяются'},
		{v: 402, name: 'Минерал возвращается'},
		{v: 403, name: 'Поступления от добычи полезных ископаемых'},
		{v: 404, name: 'Выйти из шахты'},
		{v: 405, name: 'Сборы за выход из эксплуатации'},
		{v: 501, name: 'Купить слепой ящик'},
		{v: 502, name: 'Доход от слепого ящика'},
	],
	common: {
		app_name: "Coinbase",
		confirm: "Подтверждение",
		cancel: "Отменить",
		more: "Больше.",
		main: "Главная диаграмма",
		sub: "Дополнительные диаграммы",
		empty: "Нет данных",
		success: "Успех",
		copy_success: "Репликация удалась!",
		copy_error: "Не удалось скопировать!",
		passed: "Принято",
		rejectrd: "Отказано",
		in_review: "В процессе проверки",
		nodata: "Пока нет контента.",
		delete: "Удалить",
		add: "Дополнительные",
		buy: "Купить",
		sell: "Продажа",
		max: "Максимум",
		all: "Все.",
		logout: "Вы уверены, что хотите выйти?",
		walletLink: "Пожалуйста, откройте это соединение в кошельке ！",
		connectWallet: "Подключить кошелек ",
	},
	home: {
		nav1: "Поделиться",
		nav2: "Бумажник",
		nav3: "Быстрое сообщение",
		nav4: "Учебный план",
		nav5: "Обслуживание клиентов",
		nav6: "Добыча полезных ископаемых",
		nav7: "Компания",
		quick_p1: "Быстрое пополнение",
		quick_p2: "Поддержка TRC20 - USDT, ERC20 - USDT и т.д.",
		sort_tab1: "Рейтинг роста",
		sort_tab2: "Объем 24h",
		sort_tit: "Рынок",
		sort_price: "Последние",
		sort_rate: "24h Сниж",
		sort_vol: "Объем",

	},
	form: {
		place_username_email: "Введите имя пользователя / почтовый ящик",
		place_password: "Введите пароль.",
		place_confirm_password: "Введите пароль еще раз.",
		place_email: "Введите почтовый ящик",
		send_email: "Отправить сообщение",
		place_email_captcha: "Введите код проверки почтового ящика.",
		place_username: "Введите имя пользователя",
		place_yzm: "Введите код проверки.",
		place_invite: "Введите идентификатор приглашенного",
		place_inner: "Введите содержимое",
		place_select: "Выберите",
		label_oldpwd: "Оригинальный пароль входа",
		place_oldpwd: "Введите исходный пароль",
		label_newpwd: "Новый пароль входа",
		place_newpwd: "Введите новый пароль входа",
		label_crmpwd: "Подтвердить новый пароль входа",
		place_crmpwd: "Введите новый пароль.",
		place_country: "Выберите страну",
		place_name: "Введите ваше имя",
		place_card_number: "Введите номер вашего удостоверения личности или паспорта.",
		place_card_main: "Нажмите, чтобы загрузить положительный снимок удостоверения личности",
		label_currency: "Валюта",
		place_currency: "Пожалуйста, выберите валюту",
		label_account_number: "Номер счета",
		place_account_number: "Пожалуйста, введите номер счета.",
		message_buy_amount: "Пожалуйста, введите сумму покупки.",
		place_buy_tip: "Купить хотя бы",
	},

	foot: {
		nav1: "Домашняя",
		nav2: "конъюнктура",
		nav3: "Сделки",
		nav4: "Активы",
	},
	account: {
		login_title: "Добро пожаловать.",
		register_title: "Добро пожаловать на регистрацию",
		contact: "Свяжитесь с клиентом",
		login: "Регистрация",
		no_account: "Нет аккаунта?",
		free_register: "Бесплатная регистрация",
		welcome_register: "Добро пожаловать на регистрацию",
	},
	asset: {
		asset: "Активы",
		total_asset: "Общий объем активов",
		deposit: "Заполнение",
		withdraw: "Показать",
		available: "Доступность",
		freeze: "Замораживание",
		total: "Всего",
		wallet: "Бумажник",
		select_link: "Выберите основную цепочку",
		deposit_address: "Адрес пополнения",
		deposit_tip: "Пожалуйста, не пополняйте активы, кроме USDT, на этот адрес, и любые другие активы, заполненные этим адресом, не будут возвращены.",
	},
	chart: {
		now: "В настоящее время",
		open_price: "Начальная цена",
		trans_amount: "Сумма сделки",
		volumes: "Объем",
		height: "Высший",
		low: "Минимум",
		buy: "Купить",
		sell: "Продать",
		buy_more: "Купить больше",
		sell_short: "Продажа",
		buy_long: "Больше.",
		short_sell: "Пустить",
		spots: "Наличные",
		foreign_exchange: "Валюта",
		market: "конъюнктура",
		order_tab1: "Хранить склад",
		order_tab2: "Заказы",
		order_tab3: "Накура",
		surplus: "Остаток средств",
		available_balance: "Имеющийся остаток",
		net_value: "Чистая стоимость",
		advance_charge: "Авансы",
		unrealized_profits: "Нереализованная прибыль",
		open_price2: "Открытая цена",
		now_price: "Текущая цена",
		lever_title: "Регулировочный рычаг",
		lever: "Рычаг",
		check_price: "Предельная цена",
		market_price: "Рыночная стоимость",
		number: "Количество",
		total_amount: "Общая сумма",
		price: "Цены",
		now_entrust: "Текущее поручение",
		history_entrust: "Историческое поручение",
		holding_positions: "Хранить склад",
		warehouse: "По складам",
		margin: "Гарантия",
		from: "От",
		to: "Б",
		pay: "Выплаты",
		balance: "Остаток",
		get: "Получи.",
		flash_exchange: "Флэш",
		hand_number: "Количество рук",
		registration_price: "Удельная цена",
		stop_profit: "Ликвидация",
		stop_loss: "Остановить потерю",
		need_margin: "Требуемый залог",
		current_price: "Текущая цена",
		direction: "Направление",
		currency_trade: "Денежные операции",
		delivery_time: "Время доставки",
		second: "Секунда",
		buy_amount: "Сумма покупки",
		fee: "Плата за услуги",
		confirm_order: "Подтверждение заказа",
		trade_history: "История сделок",
		turnover_number: "Количество сделок",
		turnovered: "Договорились.",
		opened: "Ожидание открытия склада",
		progress: "В настоящее время",
		ended: "Завершено",
		revoked: "Отменено",
		complete: "Завершено",
		time: "Время",
		operate: "Операция",
		closing_position: "Хиракура",
		buy_price: "Покупательная цена",
	},
	market: {
		currency_trade: "Денежные операции",
		spots: "Наличные",
		contract: "Контракт",
		adjustment_accounts: "Перераспределение",
	},
	table: {
		market: "Рынок",
		rate: "24h Сниж",
		open_price: "Начальная",
		last_price: "Последняя",
	},
	my: {
		modify_login_pwd: "Изменить пароль входа",
		company: "Компания",
		help_center: "Центр помощи",
		history: "История валютных операций",
		buy: "Купить",
		sell: "Продать",
		buy_price: "Покупательная цена",
		type: "Тип",
		amount: "Сумма",
		final_price: "Конечная цена",
		profit_loss: "Прибыль и убыток",
		state: "Статус",
		loss: "Убытки",
		profit: "Прибыль",
		news: "Быстрое сообщение",
		authentication: "Сертификация",
		service: "Обслуживание клиентов",
		asset: {
			amount_record: "Учет сумм",
			deposit_record: "Регистрация пополнения",
			withdraw_record: "Отчет о снятии наличных средств",
		},
		help: {
			p1: "Обслуживать Вас в любое время и в любом месте",
			h1: "Часто задаваемые вопросы",
		},
		share: {
			share: "Поделиться",
			invite_link: "Ссылка на приглашение",
			copy_link: "Копировать ссылку",
		},
		wallet: {
			title: "Адрес кошелька",
			account_number: "Номер счета",
			add_address: "Дополнительный адрес",

		}
	},
	finance: {
		finance_tit: "Захорочная добыча",
		finance_label1: "Ожидаемая прибыль сегодня",
		finance_label2: "Совокупные поступления",
		finance_label3: "Заказы хостинга",
		subscribe: "Заявки на закупки",
		day: "Боже.",
		record: "Запись",
		deposit_amount: "Сумма пополнения",
		received_amount: "Сумма поступления",
		exchange_rate: "Обменный курс",
		ad_p1: "Добыча полезных ископаемых продолжается.",
		ad_p2: "Захоронение осуществляется за счет размещения USDT на платформе сверхмощного майнера в бассейне платформы.",
		join: "Я участвую.",
		ad_p3: "Изюминки продукции",
		ad_p4: "Приведу пример.",
		ad_tit1: "сопровождающая выборка",
		ad_tit2: "Ежедневный выпуск",
		ad_tit3: "Безопасность",
		ad_tit4: "Доходы постоянно",
		ad_tit5: "В тот же день",
		ad_des1: "Период распределения процентов",
		ad_des2: "Текущие проценты",
		ad_des3: "100%Безопасность",
		ad_des4: "Доходы от отпусков",
		ad_des5: "После успешного внесения",
		ad_sub_tit1: "Расчет поступлений",
		ad_sub_tit2: "Сумма неустойки",
		ad_sub_p1: "Участники на платформе заперты 10000U, выберите период 5 дней, ежедневный выпуск 0,3% ~ 0,4% от суммы блокировки финансовых продуктов, то ежедневный выпуск будет следующим:",
		ad_sub_p2: "Минимальный: 10000U x 0,3% = 30 U",
		ad_sub_p3: "Максимальный: 10000U x 0,4% = 40 U",
		ad_sub_p4: "То есть через 5 дней вы можете получить прибыль от 150U ~ 200U, прибыль ежедневно отправляется, выпущенная прибыль может быть доступна в любое время, основная сумма замка после истечения срока погашения, автоматически переводится на ваш счет активов.",
		ad_sub_p5: "Если вы хотите перевести основную сумму, срок погашения которой не наступил, то возникает неустойка, которая = процент расчетов по дефолту * Количество оставшихся дней * Количество закрытых позиций.",
		ad_sub_p6: "Пример: доля дефолтных расчетов при добыче на этом замке составляет 0,5%, срок погашения остающихся 3 дней, количество замков - 1000, тогда неустойка = 0,5% * 3 * 1000 = 15U, фактическая основная сумма возврата составляет 1000U - 15U = 985U",
	},

	city: {
		albania: "阿尔巴尼亚",
		algeria: "阿尔及利亚",
		argentina: "阿根廷",
		armenia: "亚美尼亚",
		australia: "澳大利亚",
		pakistan: "巴基斯坦",
		austria: "奥地利",
		bahrain: "巴林",
		belgium: "比利时",
		bosnia_and_Herzegovina: "波黑",
		brazil: "巴西",
		brunei: "文莱",
		bulgaria: "保加利亚",
		cambodia: "柬埔寨",
		canada: "加拿大",
		cameroon: "喀麦隆",
		chile: "智利",
		colombia: "哥伦比亚",
		costa_Rica: "哥斯达黎加",
		croatia: "克罗地亚",
		cyprus: "塞浦路斯",
		czech_Republic: "捷克",
		denmark: "丹麦",
		dominican_Republic: "多米尼亚共和国",
		egypt: "埃及",
		estonia: "爱沙尼亚",
		ethiopia: "埃塞俄比亚",
		finland: "芬兰",
		france: "法国",
		georgia: "格鲁吉亚",
		germany: "德国",
		ghana: "加纳",
		greece: "希腊",
		guyana: "圭亚那",
		honduras: "洪都拉斯",
		hong_Kong_China: "中国香港",
		hungary: "匈牙利",
		iceland: "冰岛",
		ireland: "爱尔兰",
		italy: "意大利",
		india: "印度",
		indonesia: "印度尼西亚",
		israel: "以色列",
		iran: "伊朗",
		iraq: "伊拉克",
		japan: "日本",
		kazakstan: "哈萨克斯坦",
		kenya: "肯尼亚",
		korea: "韩国",
		kuwait: "科威特",
		kyrgyzstan: "吉尔吉斯斯坦",
		laos: "老挝",
		latvia: "拉脱维亚",
		lithuania: "立陶宛",
		luxembourg: "卢森堡",
		macao_China: "中国澳门",
		macedonia: "马其顿",
		malaysia: "马来西亚",
		malta: "马耳他",
		mexico: "墨西哥",
		moldova: "摩尔多瓦",
		monaco: "摩纳哥",
		mongolia: "蒙古",
		montenegro: "黑山",
		morocco: "摩洛哥",
		myanmar: "缅甸",
		netherlands: "荷兰",
		new_Zealand: "新西兰",
		nepal: "尼泊尔",
		nigeria: "尼日利亚",
		norway: "挪威",
		oman: "阿曼",
		palestine: "巴勒斯坦",
		panama: "巴拿马",
		paraguay: "巴拉圭",
		peru: "秘鲁",
		philippines: "菲律宾",
		poland: "波兰",
		portugal: "葡萄牙",
		puerto_Rico: "波多黎各",
		qatar: "卡塔尔",
		romania: "罗马尼亚",
		russia: "俄罗斯",
		republic_of_Trinidad_and_Tobago: "特立尼达和多巴哥",
		rwanda: "卢旺达",
		saudi_Arabia: "沙特阿拉伯",
		serbia: "塞尔维亚",
		singapore: "新加坡",
		slovakia: "斯洛伐克",
		slovenia: "斯洛文尼亚",
		south_Africa: "南非",
		spain: "西班牙",
		sri_Lanka: "斯里兰卡",
		sweden: "瑞典",
		switzerland: "瑞士",
		taiwan_China: "中国台湾",
		tajikistan: "塔吉克斯坦",
		tanzania: "坦桑尼亚",
		thailand: "泰国",
		turkey: "土耳其",
		turkmenistan: "土库曼斯坦",
		ukraine: "乌克兰",
		united_Arab_Emirates: "阿联酋",
		united_Kingdom: "英国",
		united_States: "美国",
		uzbekistan: "乌兹别克斯坦",
		venezuela: "委内瑞拉",
		vietnam: "越南",
		afghanistan: "阿富汗",
		angola: "安哥拉",
		azerbaijan: "阿塞拜疆",
		bangladesh: "孟加拉国",
		belarus: "白俄罗斯",
		belize: "伯利兹",
		benin: "贝宁",
		bhutan: "不丹",
		bolivia: "玻利维亚",
		botswana: "博茨瓦纳",
		british_Virgin_Islands: "英属维京群岛",
		burkina_Faso: "布基纳法索",
		burundi: "布隆迪",
		cape_Verde: "佛得角",
		cayman_Islands: "开曼群岛",
		central_African_Republic: "中非共和国",
		chad: "乍得",
		comoros: "科摩罗",
		the_Republic_of_the_Congo: "刚果（布）",
		democratic_Republic_of_the_Congo: "刚果（金）",
		djibouti: "吉布提",
		ecuador: "厄瓜多尔",
		el_Salvador: "萨尔瓦多",
		equatorial_Guinea: "赤道几内亚",
		eritrea: "厄立特里亚",
		fiji: "斐济",
		gabon: "加蓬",
		gambia: "冈比亚",
		greenland: "格陵兰",
		guatemala: "危地马拉",
		guinea: "几内亚",
		haiti: "海地",
		isle_of_Man: "马恩岛",
		cote_d_Ivoire: "科特迪瓦",
		jamaica: "牙买加",
		jordan: "约旦",
		lebanon: "黎巴嫩",
		lesotho: "莱索托",
		liberia: "利比里亚",
		libya: "利比亚",
		madagascar: "马达加斯加",
		malawi: "马拉维",
		maldives: "马尔代夫",
		mali: "马里",
		mauritania: "毛里塔尼亚",
		mauritius: "毛里求斯",
		mozambique: "莫桑比克",
		namibia: "纳米比亚",
		nicaragua: "尼加拉瓜",
		republic_of_Niger: "尼日尔",
		north_Korea: "朝鲜",
		reunion: "留尼汪",
		san_Marino: "圣马力诺",
		senegal: "塞内加尔",
		sierra_Leone: "塞拉利昂",
		somalia: "索马里",
		sudan: "苏丹",
		suriname: "苏里南",
		eswatini: "斯威士兰",
		syria: "叙利亚",
		togo: "多哥",
		tonga: "汤加",
		tunisia: "突尼斯",
		united_States_Virgin_Islands: "美属维尔京群岛",
		uganda: "乌干达",
		uruguay: "乌拉圭",
		vatican: "梵蒂冈",
		yemen: "也门",
		yugoslavia: "南斯拉夫",
		zambia: "赞比亚",
		zimbabwe: "津巴布韦",
		china: "中国",
	}
};
