export default {
    addnew: {
		messageNotice: "Dear customer, hello! Please check your new information.",
		messages: "My Message",
		completed: "Terminé",
		position: "Dans la position",
		all: "Complet",
		submit: "Soumettre ",
        realFirst: "Veuillez d'abord vérifier votre identité",
        payFirst: "Veuillez d'abord définir le mot de passe de retrait",
        password1: "Modifier le mot de passe",
        password2: "Modifier le mot de passe",
        paypassTit1: "Veuillez définir votre mot de passe de retrait",
        oldPass: "Saisir le mot de passe original du DAB",
        allView: "Tous lire",
        confirmOrder: "Confirmer la commande",
        name: "Nom",
        fee: "Frais de dossier",
        orderCycle: "Sélectionner l'heure",
        loan: "Obtenir un prêt",
        loanTit: "Prêt étudiant",
        loanHostory: "Dossiers de prêts",
        labelProductTitle: "Nom du produit",
        immediateRepayment: "Remboursement immédiat",
        loadAmount: "Montant prévu du prêt",
        RepaymentCycle: "Cycle de remboursement du prêt",
        day: "Jour",
        dailyRate: "Taux d'intérêt journalier",
        repaymentMethod: "Mode de remboursement",
        lendingCompany: "Société de prêt",
        loanTip: "Prêts (veillez à ce que les photos soient clairement visibles)",
        loanTip1: "Télécharger le certificat de propriété",
        loanTip2: "Preuve de revenus (relation de travail)",
        loanTip3: "Relevé d'identité bancaire",
        loanTip4: "Téléchargez la photo du recto de votre carte d'identité",
        interest: "Saké",
        repaid: "Remboursé",
        unpaid: "Non-remboursement",
        tab1: "Pièces de monnaie",
        tab2: "Contractuel",
        tab3: "Options",
        available: "Crédit disponible",
        pending: "Verrouillage",
        equivalent: "S'élève à",
        fundTransfer: "Transfert de fonds",
        flashExchange: "Argent liquide",
        select_breed: "Sélectionner la devise",
        from: "A travers",
        to: "Jusqu'à",
        transferQuantity: "Nombre de transferts",
        repaymentMethod1:"Remboursables en une seule fois"
    },
	addNew: {
		place_card_back: "Cliquez pour télécharger l'image inverse de votre carte d'identité",
		side1: "gestion des commandes",
		side2: "modifier le mot de passe",
		side3: "langue",
		side4: "se déconnecter",
		product: "produit",
		direction: "Direction",
		cycle: "pendant la transaction",
		long_buy: "acheter à long terme",
		short_sell: "Short - Sell",
		open_time: "heure d'ouverture",
		close_time: "heure de clôture",
		open_price: "prix d'ouverture",
		close_price: "prix de clôture",
		number: "quantité",
		profit_loss: "gagner ou perdre",
		commission: "frais de traitement",
		account_type: "type de changement de compte",
		type1: "compte coin",
		type2: "compte en monnaie française",
		type3: "compte contractuel",
		type4: "compte d'options",
		type5: "compte financier",
		message1: "Veuillez saisir le montant",
		message2: "Solde insuffisant du compte",
		place_transPsd: "Veuillez saisir le mot de passe de la transaction",
		countpath: "Fin du compte à rebours passer automatiquement à la page de commande",
		nowpath: "Sauter maintenant",
	},
    common: {
		app_name: "Coinbase",
		confirm: "Reconnaître",
		cancel: "Annulations",
		more: "Plus",
		main: "Image principale",
		sub: "Sous-titre",
		empty: "Pas de données disponibles",
		success: "Succès",
		copy_success: "Succès de copie！",
		copy_error: "Échec de la reproduction！",
		passed: "Approuvé",
		rejectrd: "Rejeté",
		in_review: "En cours d'examen",
		nodata: "Pas de contenu pour le moment",
		delete: "L'enlèvement",
		add: "Supplémentaires",
		buy: "Achat",
		sell: "Montrer ou exhiber",
		max: "Le plus grand",
		all: "Complet",
		logout:"Êtes - vous sûr de vouloir vous déconnecter?",
		walletLink: "Veuillez ouvrir cette Connexion dans votre portefeuille!",
		connectWallet: "Connecter le portefeuille",
	},
	home: {
		nav1: "Partager ",
		nav2: "Sacs à main",
		nav3: "Flash info",
		nav4: "Tutoriels",
		nav5: "Premiers secours",
		nav6: "Creuser",
		nav7: "Entreprises",
		quick_p1: "Monnaie à remplissage rapide",
		quick_p2: "Prend en charge TRC20-USDT, ERC20-USDT, etc.",
		sort_tab1: "Hausse et baisse d'un titre en une journée de négociation",
		sort_tab2: "Volume en 24h",
		sort_tit: "Marché",
		sort_price: "Derniers prix",
		sort_rate: "24 heures",
		sort_vol: "Rotation",

	},
	form: {
		place_username_email: "Veuillez saisir votre nom d'utilisateur/email",
		place_password: "Veuillez saisir votre mot de passe",
		place_confirm_password: "Veuillez saisir à nouveau votre mot de passe",
		place_email: "Veuillez saisir votre adresse électronique",
		send_email: "Courrier",
		place_email_captcha: "Veuillez saisir le code de vérification de votre courriel",
		place_username: "Veuillez saisir votre nom d'utilisateur",
		place_yzm: "Veuillez saisir le code de vérification",
		place_invite: "Veuillez saisir l'identifiant de l'invité",
		place_inner: "Veuillez saisir le contenu",
		place_select: "Veuillez sélectionner",
		label_oldpwd: "Mot de passe original",
		place_oldpwd: "Veuillez saisir le mot de passe original",
		label_newpwd: "Nouveau mot de passe",
		place_newpwd: "Veuillez saisir un nouveau mot de passe de connexion",
		label_crmpwd: "Confirmer le nouveau mot de passe",
		place_crmpwd: "Veuillez saisir à nouveau le nouveau mot de passe de connexion",
		place_country: "Veuillez sélectionner un pays",
		place_name: "Veuillez saisir votre nom",
		place_card_number: "Veuillez saisir votre numéro de carte d'identité ou de passeport",
		place_card_main: "Cliquez pour télécharger une photo du recto de votre carte d'identité",
		label_currency: "Monnaie",
		place_currency: "Veuillez sélectionner la devise",
		label_account_number: "Numéro de compte",
		place_account_number: "Veuillez saisir votre numéro de compte",
		message_buy_amount: "Veuillez saisir le montant de l'achat",
		place_buy_tip: "Acheter au moins 100",
	},

	foot: {
		nav1: "Page d'accueil",
		nav2: "La situation",
		nav3: "Transactions",
		nav4: "Passif",
	},
	account: {
		login_title: "Bienvenue à",
		register_title: "Bienvenue à l'inscription",
		contact: "Cliquez pour contacter le service clientèle",
		login: "S'inscrire",
		no_account: "Vous n'avez pas encore de compte.？",
		free_register: "Inscription gratuite",
		welcome_register: "Bienvenue à l'inscription",
	},
	asset: {
		asset: "Passif",
		total_asset: "Total des actifs",
		deposit: "Recharge",
		withdraw: "Retirer des fonds",
		available: "L'utilisabilité",
		freeze: "Le gel",
		total: "Total",
		wallet: "Sacs à main",
		select_link: "Choisir un lien principal",
		deposit_address: "Adresse de recharge",
		deposit_tip: "Veuillez ne pas ajouter d'actifs autres que des USDT à cette adresse. Tout autre actif ajouté à cette adresse ne pourra pas être récupéré.",
	},
	chart: {
		now: "être face à (nous)",
		open_price: "Prix d'ouverture",
		trans_amount: "Somme d'argent vendue",
		volumes: "Rotation",
		height: "Suprême",
		low: "Le plus bas",
		buy: "Participation",
		sell: "Atteindre",
		buy_more: "Pot-de-vin",
		sell_short: "Vendre à découvert",
		buy_long: "Long",
		short_sell: "Vente à découvert",
		spots: "Marchandises en stock",
		foreign_exchange: "Change ",
		market: "La situation actuelle du marché",
		order_tab1: "Participations",
		order_tab2: "Commande en cours",
		order_tab3: "Position fermée",
		surplus: "Soldes",
		available_balance: "Solde disponible",
		net_value: "Valeur nette",
		advance_charge: "Prépaiement",
		unrealized_profits: "Non rentable",
		open_price2: "Prix d'ouverture",
		now_price: "Prix actuel",
		lever_title: "L'effet de levier",
		lever: "Barre de levier",
		check_price: "Limite de prix",
		market_price: "Valeur du marché",
		number: "Quantités",
		total_amount: "Montant total",
		price: "Prix",
		now_entrust: "Commissions actuelles",
		history_entrust: "Commissions historiques",
		holding_positions: "Position de maintien",
		warehouse: "Tournées d'écureuil",
		margin: "Marge ",
		from: "à travers ",
		to: "Jusqu'à",
		pay: "Payer",
		balance: "Soldes",
		get: "Obtenir",
		flash_exchange: "Argent liquide",
		hand_number: "Nombre ordinal",
		registration_price: "Prix de l'ordre en attente",
		stop_profit: "Prendre des bénéfices",
		stop_loss: "Stop-loss",
		need_margin: "Dépôt requis",
		current_price: "Valeur actuelle",
		direction: "Orientations",
		currency_trade: "Opérations de change",
		delivery_time: "Délai de livraison (glissement vers la gauche pour des retours plus importants)",
		second: "Secondes",
		buy_amount: "Montant du rachat",
		fee: "Frais de dossier",
		confirm_order: "Confirmer la commande",
		trade_history: "Historique des échanges",
		turnover_number: "Nombre de transactions",
		turnovered: "Vendu",
		opened: "Dans l'attente de l'ouverture du poste",
		progress: "Vers",
		ended: "Fermé",
		revoked: "Retiré",
		complete: "Remplir",
		time: "Fois",
		operate: "Gréement",
		closing_position: "Fermer un poste",
		buy_price: "Prix de l'offre",
	},
	market: {
		currency_trade: "Opérations de change",
		spots: "Marchandises en stock",
		contract: "Contractuel",
		adjustment_accounts: "Transfert de crédits",
	},
	table: {
		market: "Marché",
		rate: "24 heures",
		open_price: "Prix d'ouverture",
		last_price: "Dernier prix",
	},
	my: {
		modify_login_pwd: "Modifier le mot de passe de connexion",
		company: "Entreprises",
		help_center: "Centre d'aide",
		history: "Histoire du commerce des devises",
		buy: "Participation",
		sell: "Atteindre",
		buy_price: "Prix d'achat",
		type: "Typologie",
		amount: "Somme d'argent",
		final_price: "Prix final",
		profit_loss: "Cire et décroissance",
		state: "état des lieux",
		loss: "Perte",
		profit: "Rentabilité",
		news: "Flash info",
		authentication: "Accréditation",
		service: "Premiers secours",
		asset: {
			amount_record: "Enregistrement des montants",
			deposit_record: "Enregistrement de la recharge",
			withdraw_record: "Registre des retraits",
		},
		help: {
			p1: "À votre service à tout moment et en tout lieu",
			h1: "Problèmes courants",
		},
		share: {
			share: "Partager",
			invite_link: "Lien d'invitation",
			copy_link: "Copier le lien",
		},
		wallet: {
			title: "Adresse du portefeuille",
			account_number: "Numéro de compte",
			add_address: "Nouvelle adresse",

		}
	},
	finance: {
		finance_tit: "Lockout mining",
		finance_label1: "Rendement attendu aujourd'hui",
		finance_label2: "Gain cumulé",
		finance_label3: "Commandes hébergées",
		subscribe: "Demander à acheter",
		day: "Jour",
		record: "Record",
		deposit_amount: "Montant complémentaire",
		received_amount: "Montant de l'argent reçu",
		exchange_rate: "Taux de change",
		ad_p1: "Exploitation minière",
		ad_p2: "L'exploitation minière verrouillée est réalisée en hébergeant l'usdt dans le pool minier de la plateforme, qui dispose d'une grande puissance de calcul, pour en tirer des revenus.",
		join: "Je veux participer.",
		ad_p3: "Points forts du produit",
		ad_p4: "Donner un exemple",
		ad_tit1: "Payer au fur et à mesure",
		ad_tit2: "Délivré quotidiennement",
		ad_tit3: "La sécurité",
		ad_tit4: "Recettes courantes",
		ad_tit5: "Intérêt à la journée",
		ad_des1: "Cycle du dividende",
		ad_des2: "Intérêts sur le compte courant",
		ad_des3: "Sécurité à 100",
		ad_des4: "Revenus de vacances",
		ad_des5: "Après un dépôt réussi",
		ad_sub_tit1: "Calcul des revenus",
		ad_sub_tit2: "Pénalité ",
		ad_sub_p1: "Si un membre bloque une position de 10.000U sur la plateforme et choisit un produit financier avec une période de 5 jours et un rendement quotidien de 0,3% à 0,4% du montant bloqué, le rendement quotidien sera le suivant :",
		ad_sub_p2: "Minimum : 10000 U x 0,3% = 30 U",
		ad_sub_p3: "Maximum : 10000 U x 0,4% = 40 U",
		ad_sub_p4: "En d'autres termes, vous pouvez obtenir 150U~200U après 5 jours, les gains sont émis quotidiennement, les gains émis sont accessibles à tout moment, et le capital bloqué sera automatiquement transféré sur votre compte d'actif à l'expiration.",
		ad_sub_p5: "Si vous souhaitez reconduire le capital restant dû, vous devrez payer des frais de défaillance, qui = Pourcentage de règlement par défaut * Nombre de jours restants * Nombre de positions bloquées.",
		ad_sub_p6: "Exemple : le ratio de règlement par défaut pour ce blocage minier est de 0,5 %, il reste 3 jours avant l'expiration et le nombre de blocages est de 1000, alors le ratio par défaut = 0,5 %*3*1000=15U, et le capital réel remboursé est de 1000U-15U=985U.",
	},
	city: {
        albania: "Albanie",
        algeria: "Algérie",
        argentina: "Argentine",
        armenia: "Arménie",
        australia: "Australie",
        pakistan: "Pakistan",
        austria: "Autriche",
        bahrain: "Bahreïn",
        belgium: "Belgique",
        bosnia_and_Herzegovina: "Bosnie-Herzégovine",
        brazil: "Brésil",
        brunei: "Brunéi",
        bulgaria: "Bulgarie",
        cambodia: "Cambodge",
        canada: "Canada",
        cameroon: "Cameroun",
        chile: "Chili",
        colombia: "Colombie",
        costa_Rica: "Costa Rica",
        croatia: "Croatie",
        cyprus: "Chypre",
        czech_Republic: "République Tchèque",
        denmark: "Danemark",
        dominican_Republic: "République Dominicaine",
        egypt: "Égypte",
        estonia: "Estonie",
        ethiopia: "Éthiopie",
        finland: "Finlande",
        france: "France",
        georgia: "Géorgie",
        germany: "Allemagne",
        ghana: "Ghana",
        greece: "Grèce",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong (Chine)",
        hungary: "Hongrie",
        iceland: "Islande",
        ireland: "Irlande",
        italy: "Italie",
        india: "Inde",
        indonesia: "Indonésie",
        israel: "Israël",
        iran: "Iran",
        iraq: "Irak",
        japan: "Japon",
        kazakstan: "Kazakhstan",
        kenya: "Kenya",
        korea: "Corée",
        kuwait: "Koweït",
        kyrgyzstan: "Kirghizistan",
        laos: "Laos",
        latvia: "Lettonie",
        lithuania: "Lituanie",
        luxembourg: "Luxembourg",
        macao_China: "Macao (Chine)",
        macedonia: "Macédoine",
        malaysia: "Malaisie",
        malta: "Malte",
        mexico: "Mexique",
        moldova: "Moldavie",
        monaco: "Monaco",
        mongolia: "Mongolie",
        montenegro: "Monténégro",
        morocco: "Maroc",
        myanmar: "Myanmar",
        netherlands: "Pays-Bas",
        new_Zealand: "Nouvelle-Zélande",
        nepal: "Népal",
        nigeria: "Nigeria",
        norway: "Norvège",
        oman: "Oman",
        palestine: "Palestine",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Pérou",
        philippines: "Philippines",
        poland: "Pologne",
        portugal: "Portugal",
        puerto_Rico: "Porto Rico",
        qatar: "Qatar",
        romania: "Roumanie",
        russia: "Russie",
        republic_of_Trinidad_and_Tobago: "République de Trinité-et-Tobago",
        rwanda: "Rwanda",
        saudi_Arabia: "Arabie Saoudite",
        serbia: "Serbie",
        singapore: "Singapour",
        slovakia: "Slovaquie",
        slovenia: "Slovénie",
        south_Africa: "Afrique du Sud",
        spain: "Espagne",
        sri_Lanka: "Sri Lanka",
        sweden: "Suède",
        switzerland: "Suisse",
        taiwan_China: "Taïwan (Chine)",
        tajikistan: "Tadjikistan",
        tanzania: "Tanzanie",
        thailand: "Thaïlande",
        turkey: "Turquie",
        turkmenistan: "Turkménistan",
        ukraine: "Ukraine",
        united_Arab_Emirates: "Émirats Arabes Unis",
        united_Kingdom: "Royaume-Uni",
        united_States: "États-Unis",
        uzbekistan: "Ouzbékistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaïdjan",
        bangladesh: "Bangladesh",
        belarus: "Biélorussie",
        belize: "Belize",
        benin: "Bénin",
        bhutan: "Bhoutan",
        bolivia: "Bolivie",
        botswana: "Botswana",
        british_Virgin_Islands: "Îles Vierges Britanniques",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Cap-Vert",
        cayman_Islands: "Îles Caïmans",
        central_African_Republic: "République Centrafricaine",
        chad: "Tchad",
        comoros: "Comores",
        the_Republic_of_the_Congo: "République du Congo",
        democratic_Republic_of_the_Congo: "République Démocratique du Congo",
        djibouti: "Djibouti",
        ecuador: "Équateur",
        el_Salvador: "Salvador",
        equatorial_Guinea: "Guinée Équatoriale",
        eritrea: "Érythrée",
        fiji: "Fidji",
        gabon: "Gabon",
        gambia: "Gambie",
        greenland: "Groenland",
        guatemala: "Guatemala",
        guinea: "Guinée",
        haiti: "Haïti",
        isle_of_Man: "Île de Man",
        cote_d_Ivoire: "Côte d'Ivoire",
        jamaica: "Jamaïque",
        jordan: "Jordanie",
        lebanon: "Liban",
        lesotho: "Lesotho",
        liberia: "Libéria",
        libya: "Libye",
        madagascar: "Madagascar",
        malawi: "Malawi",
        maldives: "Maldives",
        mali: "Mali",
        mauritania: "Mauritanie",
        mauritius: "Île Maurice",
        mozambique: "Mozambique",
        namibia: "Namibie",
        nicaragua: "Nicaragua",
        republic_of_Niger: "République du Niger",
        north_Korea: "Corée du Nord",
        reunion: "Réunion",
        san_Marino: "Saint-Marin",
        senegal: "Sénégal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalie",
        sudan: "Soudan",
        suriname: "Suriname",
        eswatini: "Eswatini",
        syria: "Syrie",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisie",
        united_States_Virgin_Islands: "Îles Vierges des États-Unis",
        uganda: "Ouganda",
        uruguay: "Uruguay",
        vatican: "Vatican",
        yemen: "Yémen",
        yugoslavia: "Yougoslavie",
        zambia: "Zambie",
        zimbabwe: "Zimbabwe",
        china: "Chine",
    }
};
