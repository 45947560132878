export default {
    addnew: {
		messageNotice: "Dear customer, hello! Please check your new information.",
		messages: "My Message",
		completed: "Completado",
		position: "En posición",
		all: "Todo",
		submit: "Presentación",
        realFirst: "Por favor, realice la autenticación de identidad primero.",
        payFirst: "Por favor, establezca primero la contraseña de la transacción.",
        password1: "Modificar la contraseña de inicio de sesión",
        password2: "Modificar la contraseña de retiro",
        paypassTit1: "Por favor, establezca su contraseña de retiro.",
        oldPass: "Contraseña antigua",
        allView: "Todo leído",
        confirmOrder: "Confirmar pedido",
        name: "Nombre",
        fee: "Comisiones",
        orderCycle: "Elegir el tiempo",
        loan: "Elegir el tiempo",
        loanTit: "Préstamos asistidos",
        loanHostory: "Registros de préstamos",
        labelProductTitle: "Nombre del producto",
        immediateRepayment: "Reembolso inmediato",
        loadAmount: "Importe previsto del préstamo",
        RepaymentCycle: "Ciclo de reembolso del préstamo",
        day: "Días",
        dailyRate: "Tasa de interés diaria",
        repaymentMethod: "Método de reembolso",
        lendingCompany: "Empresas de préstamos",
        loanTip: "Préstamos de crédito (asegúrese de que la imagen sea claramente visible)",
        loanTip1: "Cargar certificado de propiedad",
        loanTip2: "Certificado de ingresos (relación laboral)",
        loanTip3: "Detalles de la factura bancaria",
        loanTip4: "Sube la foto frontal de la tarjeta de identificación",
        interest: "Intereses",
        repaid: "Ha sido reembolsado",
        unpaid: "No reembolsado",
        tab1: "Monedas y monedas",
        tab2: "Contrato",
        tab3: "Opciones",
        available: "Cuota disponible",
        pending: "Bloqueo",
        equivalent: "Equivalente",
        fundTransfer: "Transferencia de fondos",
        flashExchange: "Cambio flash",
        select_breed: "Elegir la moneda",
        from: "De",
        to: "A",
        transferQuantity: "Número de transferencias",
        repaymentMethod1:"Reembolso único al vencimiento"
    },
	addNew: {
		place_card_back: "Clique para carregar uma foto do verso do seu cartão de identificação",
		side1: "Gestão de encomendas",
		side2: "Alterar senha",
		side3: "Linguagem",
		side4: "Log out of login",
		product: "Produto",
		direction: "Direcção",
		cycle: "Período de transacção",
		long_buy: "Buy long",
		short_sell: "Short sell",
		open_time: "Opening time",
		close_time: "Closing Time",
		open_price: "Preço de abertura",
		close_price: "Preço de encerramento",
		number: "Quantidade",
		profit_loss: "Lucros e perdas",
		commission: "Taxa de tratamento",
		account_type: "Tipo de alteração de conta",
		type1: "Conta de moeda",
		type2: "Conta de moeda legal",
		type3: "Conta contratual",
		type4: "Conta de opção",
		type5: "Conta de gestão de património",
		message1: "Introduza o montante",
		message2: "Saldo insuficiente da conta",
		place_transPsd: "Por favor insira a senha da transação",
		countpath: "Saltar automáticamente a la página de pedido al final de la cuenta atrás",
		nowpath: "Saltar de inmediato",
	},
    common: {
		app_name: "Coinbase",
		confirm: "Reconocer",
		cancel: "Anulaciones",
		more: "Más",
		main: "Imagen principal",
		sub: "Subtítulo",
		empty: "No hay datos disponibles",
		success: "éxitos",
		copy_success: "Copiar con éxito！",
		copy_error: "Fallo de reproducción！",
		passed: "Aprobado",
		rejectrd: "Rechazado",
		in_review: "En revisión",
		nodata: "Sin contenido por el momento",
		delete: "Eliminar",
		add: "Adicional",
		buy: "Comprar",
		sell: "Presumir o alardear",
		max: "Mayor",
		all: "Completo",
		logout:"estás seguro de que quieres salir?",
		walletLink: "¡¡ por favor, abra esta conexión en su billetera!",
		connectWallet: "Conexión a la billetera",
	},
	home: {
		nav1: "Compartir",
		nav2: "Monederos",
		nav3: "Noticias",
		nav4: "Tutoriales",
		nav5: "Primeros auxilios",
		nav6: "Dig",
		nav7: "Empresas",
		quick_p1: "Moneda de llenado rápido",
		quick_p2: "Compatible con TRC20-USDT, ERC20-USDT, etc.",
		sort_tab1: "Subida y bajada de las acciones en un día de negociación",
		sort_tab2: "Volumen 24h",
		sort_tit: "Mercado",
		sort_price: "Últimos precios",
		sort_rate: "24H subida",
		sort_vol: "Facturación",

	},
	form: {
		place_username_email: "Introduzca su nombre de usuario/correo electrónico",
		place_password: "Introduzca su contraseña",
		place_confirm_password: "Vuelva a introducir su contraseña",
		place_email: "Introduzca su dirección de correo electrónico",
		send_email: "Correo",
		place_email_captcha: "Introduzca el código de verificación de correo electrónico",
		place_username: "Introduzca su nombre de usuario",
		place_yzm: "Introduzca el código de verificación",
		place_invite: "Por favor, introduzca el ID del invitado",
		place_inner: "Introduzca el contenido",
		place_select: "Seleccione",
		label_oldpwd: "Contraseña original",
		place_oldpwd: "Introduzca la contraseña de inicio de sesión original",
		label_newpwd: "Nueva contraseña de inicio de sesión",
		place_newpwd: "Introduzca una nueva contraseña de acceso",
		label_crmpwd: "Confirmar nueva contraseña de acceso",
		place_crmpwd: "Vuelva a introducir la nueva contraseña de acceso",
		place_country: "Seleccione un país",
		place_name: "Introduzca su nombre",
		place_card_number: "Introduzca su número de DNI o pasaporte",
		place_card_main: "Haga clic aquí para subir una foto del anverso de su documento de identidad",
		label_currency: "Divisa",
		place_currency: "Seleccione la moneda",
		label_account_number: "Número de cuenta",
		place_account_number: "Introduzca su número de cuenta",
		message_buy_amount: "Introduzca el importe de la compra",
		place_buy_tip: "Compre al menos 100",
	},

	foot: {
		nav1: "Página inicio",
		nav2: "La situación",
		nav3: "Tratos",
		nav4: "Pasivo",
	},
	account: {
		login_title: "Bienvenido a",
		register_title: "Bienvenido a registrarse",
		contact: "Haga clic para ponerse en contacto con el servicio de atención al cliente",
		login: "Iniciar sesión",
		no_account: "Aún no tengo cuenta.？",
		free_register: "Inscripción gratuita",
		welcome_register: "Bienvenido a registrarse",
	},
	asset: {
		asset: "Pasivo",
		total_asset: "Activos totales",
		deposit: "Recarga",
		withdraw: "Retirar fondos",
		available: "Usabilidad",
		freeze: "Congelación",
		total: "Total (general)",
		wallet: "Monederos",
		select_link: "Elija un enlace principal",
		deposit_address: "Dirección de recarga",
		deposit_tip: "Por favor, no añada activos que no sean USDT a esta dirección, cualquier otro activo añadido a esta dirección no será recuperable.",
	},
	chart: {
		now: "Estar de cara (a nosotros)",
		open_price: "Precio de apertura",
		trans_amount: "Suma de dinero vendida",
		volumes: "Facturación",
		height: "Supremo",
		low: "Más bajo",
		buy: "Buy-in",
		sell: "Alcance",
		buy_more: "Soborno",
		sell_short: "Vender en corto",
		buy_long: "Ir en largo (en un mercado)",
		short_sell: "Venta al descubierto",
		spots: "Mercancía en stock",
		foreign_exchange: "Cambio de divisas",
		market: "La situación actual del mercado",
		order_tab1: "Holdings",
		order_tab2: "Pedido pendiente",
		order_tab3: "Posición cerrada",
		surplus: "Saldos",
		available_balance: "Saldo disponible",
		net_value: "Valor neto",
		advance_charge: "Prepago",
		unrealized_profits: "No rentable",
		open_price2: "Precio de apertura",
		now_price: "Precio actual",
		lever_title: "Apalancamiento ",
		lever: "Palanca",
		check_price: "Límite de precio",
		market_price: "Valor de mercado",
		number: "Cantidades",
		total_amount: "Importe total",
		price: "Precios",
		now_entrust: "Comisiones actuales",
		history_entrust: "Comisiones históricas",
		holding_positions: "Mantener posición",
		warehouse: "Barnstorming",
		margin: "Margen",
		from: "A través de",
		to: "Hasta",
		pay: "Pagar (dinero)",
		balance: "Saldos",
		get: "Consiga",
		flash_exchange: "Efectivo",
		hand_number: "Número ordinal",
		registration_price: "Precio de la orden pendiente",
		stop_profit: "Obtener beneficios",
		stop_loss: "Stop-loss",
		need_margin: "Depósito requerido",
		current_price: "Valor actual",
		direction: "Orientaciones",
		currency_trade: "Comercio de divisas",
		delivery_time: "Plazo de entrega (deslizamiento a la izquierda para mayores devoluciones)",
		second: "Segundos",
		buy_amount: "Importe del buy-in",
		fee: "Gastos de tramitación",
		confirm_order: "Confirmar pedido",
		trade_history: "Historial comercial",
		turnover_number: "Número de transacciones",
		turnovered: "Vendido",
		opened: "Puesto pendiente de apertura",
		progress: "Hacia",
		ended: "Cerrado",
		revoked: "Retirado",
		complete: "Cumplir",
		time: "Veces",
		operate: "Plataforma",
		closing_position: "Cerrar una posición",
		buy_price: "Precio de oferta",
	},
	market: {
		currency_trade: "Comercio de divisas",
		spots: "Mercancía en stock",
		contract: "Contractual",
		adjustment_accounts: "Transferencia de créditos",
	},
	table: {
		market: "Mercado",
		rate: "24H subida",
		open_price: "Precio de apertura",
		last_price: "último precio",
	},
	my: {
		modify_login_pwd: "Cambiar contraseña de inicio de sesión",
		company: "Empresas",
		help_center: "Centro de ayuda",
		history: "Historia del comercio de divisas",
		buy: "Buy-in",
		sell: "Alcance",
		buy_price: "Precio de compra",
		type: "Tipología",
		amount: "Suma de dinero",
		final_price: "Precio final",
		profit_loss: "Creciente y menguante",
		state: "Situación",
		loss: "Pérdida (financiera)",
		profit: "Rentabilidad",
		news: "Noticias",
		authentication: "Acreditación",
		service: "Primeros auxilios",
		asset: {
			amount_record: "Registro de importes",
			deposit_record: "Registro de recarga",
			withdraw_record: "Registros de bajas",
		},
		help: {
			p1: "A su servicio en cualquier momento y lugar",
			h1: "Problemas comunes",
		},
		share: {
			share: "Compartir",
			invite_link: "Enlace de invitación",
			copy_link: "Copiar enlace",
		},
		wallet: {
			title: "Dirección de la cartera",
			account_number: "Número de cuenta",
			add_address: "Nueva dirección",

		}
	},
	finance: {
		finance_tit: "Cierre de minas",
		finance_label1: "Retorno previsto para hoy",
		finance_label2: "Ganancia acumulada",
		finance_label3: "Pedidos alojados",
		subscribe: "Pedir comprar",
		day: "Día",
		record: "Récord",
		deposit_amount: "Importe de recarga",
		received_amount: "Cantidad de dinero recibida",
		exchange_rate: "Tipos de cambio",
		ad_p1: "Minería",
		ad_p2: "Lock-in minería se realiza mediante el alojamiento de usdt a la plataforma minera de potencia de supercomputación en la plataforma minera piscina para los ingresos mineros",
		join: "Quiero participar.",
		ad_p3: "Productos destacados",
		ad_p4: "Dar un ejemplo",
		ad_tit1: "Pago por uso",
		ad_tit2: "Emitido diariamente",
		ad_tit3: "Seguridad",
		ad_tit4: "Ingresos corrientes",
		ad_tit5: "Interés el mismo día",
		ad_des1: "Ciclo de dividendos",
		ad_des2: "Intereses por cuenta corriente",
		ad_des3: "100% De seguridad",
		ad_des4: "Ingresos por vacaciones",
		ad_des5: "Una vez realizado el ingreso",
		ad_sub_tit1: "Cálculo de beneficios",
		ad_sub_tit2: "Sanción (tasa)",
		ad_sub_p1: "Si un miembro bloquea una posición de 10.000U en la plataforma y elige un producto financiero con un periodo de 5 días y un rendimiento diario del 0,3% al 0,4% del importe bloqueado, el rendimiento diario será el siguiente:",
		ad_sub_p2: "Mínimo: 10000 U x 0,3% = 30 U",
		ad_sub_p3: "Máximo: 10000 U x 0,4% = 40 U",
		ad_sub_p4: "Es decir, puede obtener 150U~200U después de 5 días, las ganancias se emiten diariamente, se puede acceder a las ganancias emitidas en cualquier momento, y el capital bloqueado se transferirá automáticamente a su cuenta de activos al vencimiento.",
		ad_sub_p5: "Si desea renovar el principal pendiente, incurrirá en una comisión por impago, que = Porcentaje de liquidación por impago * Número de días restantes * Número de posiciones bloqueadas.",
		ad_sub_p6: "Ejemplo: el coeficiente de liquidación por defecto de esta minería de bloqueo es del 0,5%, faltan 3 días para el vencimiento y el número de bloqueos es 1000, entonces por defecto = 0,5%*3*1000=15U, y el principal real reembolsado es 1000U-15U=985U.",
	},
	city: {
        albania: "Albania",
        algeria: "Argelia",
        argentina: "Argentina",
        armenia: "Armenia",
        australia: "Australia",
        pakistan: "Pakistán",
        austria: "Austria",
        bahrain: "Baréin",
        belgium: "Bélgica",
        bosnia_and_Herzegovina: "Bosnia y Herzegovina",
        brazil: "Brasil",
        brunei: "Brunéi",
        bulgaria: "Bulgaria",
        cambodia: "Camboya",
        canada: "Canadá",
        cameroon: "Camerún",
        chile: "Chile",
        colombia: "Colombia",
        costa_Rica: "Costa Rica",
        croatia: "Croacia",
        cyprus: "Chipre",
        czech_Republic: "República Checa",
        denmark: "Dinamarca",
        dominican_Republic: "República Dominicana",
        egypt: "Egipto",
        estonia: "Estonia",
        ethiopia: "Etiopía",
        finland: "Finlandia",
        france: "Francia",
        georgia: "Georgia",
        germany: "Alemania",
        ghana: "Ghana",
        greece: "Grecia",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong (China)",
        hungary: "Hungría",
        iceland: "Islandia",
        ireland: "Irlanda",
        italy: "Italia",
        india: "India",
        indonesia: "Indonesia",
        israel: "Israel",
        iran: "Irán",
        iraq: "Irak",
        japan: "Japón",
        kazakhstan: "Kazajistán",
        kenya: "Kenia",
        korea: "Corea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kirguistán",
        laos: "Laos",
        latvia: "Letonia",
        lithuania: "Lituania",
        luxembourg: "Luxemburgo",
        macao_China: "Macao (China)",
        macedonia: "Macedonia",
        malaysia: "Malasia",
        malta: "Malta",
        mexico: "México",
        moldova: "Moldavia",
        monaco: "Mónaco",
        mongolia: "Mongolia",
        montenegro: "Montenegro",
        morocco: "Marruecos",
        myanmar: "Myanmar",
        netherlands: "Países Bajos",
        new_Zealand: "Nueva Zelanda",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Noruega",
        oman: "Omán",
        palestine: "Palestina",
        panama: "Panamá",
        paraguay: "Paraguay",
        peru: "Perú",
        philippines: "Filipinas",
        poland: "Polonia",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Catar",
        romania: "Rumania",
        russia: "Rusia",
        republic_of_Trinidad_and_Tobago: "República de Trinidad y Tobago",
        rwanda: "Ruanda",
        saudi_Arabia: "Arabia Saudita",
        serbia: "Serbia",
        singapore: "Singapur",
        slovakia: "Eslovaquia",
        slovenia: "Eslovenia",
        south_Africa: "Sudáfrica",
        spain: "España",
        sri_Lanka: "Sri Lanka",
        sweden: "Suecia",
        switzerland: "Suiza",
        taiwan_China: "Taiwán (China)",
        tajikistan: "Tayikistán",
        tanzania: "Tanzania",
        thailand: "Tailandia",
        turkey: "Turquía",
        turkmenistan: "Turkmenistán",
        ukraine: "Ucrania",
        united_Arab_Emirates: "Emiratos Árabes Unidos",
        united_Kingdom: "Reino Unido",
        united_States: "Estados Unidos",
        uzbekistan: "Uzbekistán",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afganistán",
        angola: "Angola",
        azerbaijan: "Azerbaiyán",
        bangladesh: "Bangladesh",
        belarus: "Bielorrusia",
        belize: "Belice",
        benin: "Benín",
        bhutan: "Bután",
        bolivia: "Bolivia",
        botswana: "Botsuana",
        british_Virgin_Islands: "Islas Vírgenes Británicas",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Cabo Verde",
        cayman_Islands: "Islas Caimán",
        central_African_Republic: "República Centroafricana",
        chad: "Chad",
        comoros: "Comoras",
        the_Republic_of_the_Congo: "República del Congo",
        democratic_Republic_of_the_Congo: "República Democrática del Congo",
        djibouti: "Yibuti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Guinea Ecuatorial",
        eritrea: "Eritrea",
        fiji: "Fiyi",
        gabon: "Gabón",
        gambia: "Gambia",
        greenland: "Groenlandia",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haití",
        isle_of_Man: "Isla de Man",
        cote_d_Ivoire: "Costa de Marfil",
        jamaica: "Jamaica",
        jordan: "Jordania",
        lebanon: "Líbano",
        lesotho: "Lesoto",
        liberia: "Liberia",
        libya: "Libia",
        madagascar: "Madagascar",
        malawi: "Malaui",
        maldives: "Maldivas",
        mali: "Malí",
        mauritania: "Mauritania",
        mauritius: "Mauricio",
        mozambique: "Mozambique",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "República de Níger",
        north_Korea: "Corea del Norte",
        reunion: "Reunión",
        san_Marino: "San Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leona",
        somalia: "Somalia",
        sudan: "Sudán",
        suriname: "Surinam",
        eswatini: "Esuatini",
        syria: "Siria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Túnez",
        united_States_Virgin_Islands: "Islas Vírgenes de los Estados Unidos",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Ciudad del Vaticano",
        yemen: "Yemen",
        yugoslavia: "Yugoslavia",
        zambia: "Zambia",
        zimbabwe: "Zimbabue",
        china: "China",
    }
};
