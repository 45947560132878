export default {
	addnew: {
		messageNotice: "Dear customer, hello! Please check your new information.",
		messages: "My Message",
		completed: "انتهى",
		position: "في موقف",
		all: "كامل",
		submit: "قدم",
        realFirst: "يرجى التحقق من الهوية الأولى",
        payFirst: "الرجاء تعيين كلمة السر أولا",
        password1: "تغيير كلمة المرور",
        password2: "تغيير كلمة السر",
        paypassTit1: "الرجاء تعيين كلمة السر الخاصة بك سحب",
        oldPass: "أدخل كلمة السر الأصلية",
        allView: "قراءة جميع",
        confirmOrder: "تأكيد النظام",
        name: "إسم",
        fee: "رسوم المناولة",
        orderCycle: "اختيار الوقت",
        loan: "قرض .",
        loanTit: "مساعدة القرض",
        loanHostory: "سجل القرض",
        labelProductTitle: "اسم المنتج",
        immediateRepayment: "سداد فوري",
        loadAmount: "مبلغ القرض المتوقع",
        RepaymentCycle: "دورة سداد القرض",
        day: "أيام .",
        dailyRate: "سعر الفائدة اليومي",
        repaymentMethod: "طريقة السداد",
        lendingCompany: "الولايات المتحدة > > كانساس > > Cambridge > > شركات القروض",
        loanTip: "القروض الائتمانية ( يرجى التأكد من أن الصورة واضحة للعيان )",
        loanTip1: "تحميل شهادة الملكية",
        loanTip2: "إثبات الدخل ( علاقة العمل )",
        loanTip3: "تفاصيل بيان البنك",
        loanTip4: "تحميل صورة بطاقة الهوية",
        interest: "الفائدة",
        repaid: "سداد",
        unpaid: "عدم السداد",
        tab1: "عملة",
        tab2: "عقد",
        tab3: "الخيار",
        available: "المبلغ المتاح",
        pending: "قفل .",
        equivalent: "تحويل",
        fundTransfer: "تحويل الأموال",
        flashExchange: "فلاش",
        select_breed: "اختيار العملة",
        from: "من .",
        to: "إلى",
        transferQuantity: "نقل الكمية",
        repaymentMethod1:"الدفع عند الاستحقاق"
    },
	addNew: {
		place_card_back: "انقر فوق تحميل صورة بطاقة الهوية",
		side1: "إدارة النظام",
		side2: "تعديل كلمة المرور",
		side3: "لغة",
		side4: "خروج الدخول",
		product: "المنتجات",
		direction: "الاتجاه",
		cycle: "فترة التداول",
		long_buy: "شراء طويلة",
		short_sell: "بيع قصيرة",
		open_time: "فتح الوقت",
		close_time: "وقت الإغلاق",
		open_price: "سعر الافتتاح",
		close_price: "سعر الإغلاق",
		number: "الكمية",
		profit_loss: "الربح والخسارة",
		commission: "رسوم المناولة",
		account_type: "نوع الحساب",
		type1: "ح العملة",
		type2: "حالعملة",
		type3: "حالعقد",
		type4: "حالخيار",
		type5: "حمالي",
		message1: "من فضلك أدخل المبلغ",
		message2: "رصيد الحساب غير كافية",
		place_transPsd: "الرجاء إدخال كلمة السر",
		countpath: "العد التنازلي ينتهي تلقائيا القفز إلى صفحة النظام",
		nowpath: "القفز على الفور",
	},
	billTypes: [
		{v: 101, name: 'شحن'},
		{v: 102, name: 'تودع'},
		{v: 103, name: 'تجمد'},
		{v: 104, name: 'مذاب'},
		{v: 105, name: 'تودع'},
		{v: 106, name: 'تودع'},
		{v: 201, name: 'تجميد'},
		{v: 202, name: 'عرض'},
		{v: 203, name: 'عرض النجاح'},
		{v: 204, name: 'فشل العرض'},
		{v: 205, name: 'رسم'},
		{v: 206, name: 'خروج'},
		{v: 207, name: 'في'},
		{v: 208, name: 'عملة عملة'},
		{v: 209, name: 'عملة في'},
		{v: 301, name: 'عملة رسوم المناولة'},
		{v: 302, name: 'دخل العقد'},
		{v: 303, name: 'خسائر العقود'},
		{v: 311, name: 'خيارات الشراء'},
		{v: 312, name: 'خيارات الدخل'},
		{v: 313, name: 'خيار العودة'},
		{v: 314, name: 'رسوم الخيار'},
		{v: 315, name: 'تجميد شراء العملة'},
		{v: 316, name: 'خصم شراء العملة'},
		{v: 317, name: 'عودة العملة'},
		{v: 318, name: 'شراء العملة في الحساب'},
		{v: 319, name: 'شراء العملة في الحساب'},
		{v: 320, name: 'العملة المجمدة'},
		{v: 321, name: 'بيع العملة الخصم'},
		{v: 322, name: 'بيع عملة العودة'},
		{v: 323, name: 'عملة تباع في الحساب'},
		{v: 324, name: 'عملة تباع في الحساب'},
		{v: 325, name: 'عملة رسوم المناولة'},
		{v: 401, name: 'إضافة آلة'},
		{v: 402, name: 'عودة الألغام'},
		{v: 403, name: 'دخل المنجم'},
		{v: 404, name: 'خروج'},
		{v: 405, name: 'رسوم خروج'},
		{v: 501, name: 'أعمى مربع شراء'},
		{v: 502, name: 'أعمى مربع الدخل'},
	],
	common: {
		app_name: "Coinbase",
		confirm: "أكّد",
		cancel: "ألغى",
		more: "أكثر .",
		main: "الرسم البياني الرئيسي",
		sub: "مخطط ثانوي",
		empty: "بيانات مؤقتة",
		success: "النجاح .",
		copy_success: "نسخة ناجحة！",
		copy_error: "فشل النسخ！",
		passed: "مرت",
		rejectrd: "رفض",
		in_review: "مراجعة",
		nodata: "لا محتوى مؤقتا",
		delete: "حذف .",
		add: "إضافة",
		buy: "شراء",
		sell: "بيع .",
		max: "ماكس",
		all: "كامل",
		logout: "هل أنت متأكد أنك تريد تسجيل الخروج ؟？",
		walletLink: "يرجى فتح هذا الرابط في محفظتك！",
		connectWallet: "ربط المحفظة",
	},
	home: {
		nav1: "شارك",
		nav2: "محفظة",
		nav3: "تنبيهات",
		nav4: "تعليمي",
		nav5: "خدمة العملاء",
		nav6: "حفر",
		nav7: "شركة",
		quick_p1: "شحن سريع",
		quick_p2: "دعم trc20-usdt erc20-usdt , الخ .",
		sort_tab1: "زيادة قائمة",
		sort_tab2: "حجم",
		sort_tit: "السوق .",
		sort_price: "أحدث الأسعار",
		sort_rate: "ارتف نخفاض",
		sort_vol: "حجم",

	},
	form: {
		place_username_email: "الرجاء إدخال اسم المستخدم / البريد الإلكتروني",
		place_password: "الرجاء إدخال كلمة السر",
		place_confirm_password: "الرجاء إدخال كلمة المرور مرة أخرى",
		place_email: "من فضلك أدخل صندوق البريد",
		send_email: "إرسال بريد إلكتروني",
		place_email_captcha: "الرجاء إدخال رمز التحقق من البريد",
		place_username: "الرجاء إدخال اسم المستخدم",
		place_yzm: "الرجاء إدخال رمز التحقق",
		place_invite: "يرجى إدخال رقم المتصل",
		place_inner: "الرجاء إدخال المحتوى",
		place_select: "الرجاء اختيار",
		label_oldpwd: "كلمة السر الأصلية",
		place_oldpwd: "الرجاء إدخال كلمة السر الأصلية",
		label_newpwd: "كلمة السر الجديدة",
		place_newpwd: "الرجاء إدخال كلمة المرور الجديدة",
		label_crmpwd: "تأكيد كلمة المرور الجديدة",
		place_crmpwd: "الرجاء إدخال كلمة المرور الجديدة مرة أخرى",
		place_country: "الرجاء اختيار البلد",
		place_name: "من فضلك أدخل اسمك",
		place_card_number: "من فضلك أدخل رقم الهوية أو رقم جواز السفر",
		place_card_main: "انقر فوق تحميل صورة بطاقة الهوية",
		label_currency: "نقود",
		place_currency: "الرجاء اختيار العملة",
		label_account_number: "رقم الحساب",
		place_account_number: "الرجاء إدخال رقم الحساب",
		message_buy_amount: "يرجى إدخال مبلغ الشراء",
		place_buy_tip: "شراء على الأقل",
	},

	foot: {
		nav1: "الصفحة الرئيسية",
		nav2: "اقتباسات",
		nav3: "صفقة",
		nav4: "الأصول",
	},
	account: {
		login_title: "مرحبا بكم في",
		register_title: "مرحبا بكم في التسجيل",
		contact: "انقر فوق الاتصال بخدمة العملاء",
		login: "سجل",
		no_account: "لا حساب",
		free_register: "تسجيل مجاني",
		welcome_register: "مرحبا بكم في التسجيل",
	},
	asset: {
		asset: "الأصول",
		total_asset: "مجموع الأصول",
		deposit: "شحن",
		withdraw: "عرض",
		available: "متاح",
		freeze: "تجمد",
		total: "مجموع",
		wallet: "محفظة",
		select_link: "اختيار السلسلة الرئيسية",
		deposit_address: "عنوان الشحن",
		deposit_tip: "يرجى عدم شحن أي أصول أخرى غير usdt إلى هذا العنوان ، أي أصول أخرى غير usdt سيتم استردادها .",
	},
	chart: {
		now: "حالي",
		open_price: "سعر الافتتاح",
		trans_amount: "مبلغ الصفقة",
		volumes: "حجم",
		height: "أعلى",
		low: "الحد الأدنى",
		buy: "شراء",
		sell: "بيع",
		buy_more: "شراء أكثر",
		sell_short: "بيع قصيرة",
		buy_long: "القيام بالكثير",
		short_sell: "بيع قصيرة",
		spots: "بقعة",
		foreign_exchange: "فوركس",
		market: "اقتباسات",
		order_tab1: "موقف",
		order_tab2: "قائمة معلقة",
		order_tab3: "أغلقت",
		surplus: "أرصدة",
		available_balance: "الرصيد المتاح",
		net_value: "صافي القيمة",
		advance_charge: "الدفع مقدما",
		unrealized_profits: "الأرباح غير المحققة",
		open_price2: "سعر الافتتاح",
		now_price: "السعر الحالي",
		lever_title: "ضبط رافعة",
		lever: "رافعة",
		check_price: "تحديد الأسعار",
		market_price: "سعر السوق",
		number: "الكمية",
		total_amount: "مجموع المبلغ",
		price: "الأسعار",
		now_entrust: "اللجنة الحالية",
		history_entrust: "تاريخ التكليف",
		holding_positions: "عقد موقف",
		warehouse: "مستودع",
		margin: "هامش",
		from: "من .",
		to: "إلى",
		pay: "دفع",
		balance: "رصيد",
		get: "الحصول على",
		flash_exchange: "فلاش",
		hand_number: "عدد الأيدي",
		registration_price: "سعر الوحدة",
		stop_profit: "وقف الفائض",
		stop_loss: "وقف الخسارة",
		need_margin: "الهامش المطلوب",
		current_price: "السعر الحالي",
		direction: "الاتجاه",
		currency_trade: "معاملات العملة",
		delivery_time: "وقت التسليم",
		second: "ثانية .",
		buy_amount: "مبلغ الشراء",
		fee: "رسوم المناولة",
		confirm_order: "تأكيد النظام",
		trade_history: "تاريخ المعاملات",
		turnover_number: "عدد المعاملات",
		turnovered: "صفقة",
		opened: "فتح",
		progress: "في التقدم",
		ended: "انتهى",
		revoked: "إلغاء",
		complete: "اكتمل",
		time: "الوقت .",
		operate: "العمليات",
		closing_position: "إغلاق",
		buy_price: "سعر الشراء",
	},
	market: {
		currency_trade: "معاملات العملة",
		spots: "بقعة",
		contract: "عقد",
		adjustment_accounts: "نقل الحساب",
	},
	table: {
		market: "السوق .",
		rate: "ارتانخفاض",
		open_price: "سعر الافتتاح",
		last_price: "أحدث الأسعار",
	},
	my: {
		modify_login_pwd: "تغيير كلمة السر",
		company: "شركة",
		help_center: "مركز المساعدة",
		history: "تاريخ تداول العملات",
		buy: "شراء",
		sell: "بيع",
		buy_price: "سعر الشراء",
		type: "نوع .",
		amount: "مبلغ",
		final_price: "السعر النهائي",
		profit_loss: "الربح والخسارة",
		state: "مركز",
		loss: "خسارة",
		profit: "ربح",
		news: "تنبيهات",
		authentication: "تصديق",
		service: "خدمة العملاء",
		asset: {
			amount_record: "سجل المبلغ",
			deposit_record: "سجل شحن",
			withdraw_record: "سجل النقدية",
		},
		help: {
			p1: "في أي وقت وفي أي مكان لتقديم الخدمات لك",
			h1: "أسئلة وأجوبة",
		},
		share: {
			share: "شارك",
			invite_link: "دعوة الرابط",
			copy_link: "نسخ الرابط",
		},
		wallet: {
			title: "عنوان المحفظة",
			account_number: "رقم الحساب",
			add_address: "إضافة عنوان",

		}
	},
	finance: {
		finance_tit: "lockhouse الحفر",
		finance_label1: "الإيرادات المتوقعة اليوم",
		finance_label2: "الدخل التراكمي",
		finance_label3: "أوامر الاستضافة",
		subscribe: "طلب الشراء",
		day: "أيام .",
		record: "سجل",
		deposit_amount: "شحن المبلغ",
		received_amount: "مبلغ الحساب",
		exchange_rate: "سعر الصرف",
		ad_p1: "التعدين لا تتوقف عن كسب",
		ad_p2: "من خلال إدارة usdt إلى منصة آلة حساب قوة عظمى ، ونحن الاستفادة من التعدين",
		join: "أريد المشاركة",
		ad_p3: "تسليط الضوء على المنتجات",
		ad_p4: "على سبيل المثال",
		ad_tit1: "مع حفظ",
		ad_tit2: "يوميا",
		ad_tit3: "ضمان الأمن",
		ad_tit4: "الدخل المستمر",
		ad_tit5: "في نفس اليوم",
		ad_des1: "دورة الأرباح",
		ad_des2: "الفائدة تحت الطلب",
		ad_des3: "أمان",
		ad_des4: "عطلة الدخل",
		ad_des5: "بعد نجاح إيداع",
		ad_sub_tit1: "حساب الدخل",
		ad_sub_tit2: "الأضرار المصفاة",
		ad_sub_p1: "الأعضاء في منصة قفل 10 ، 000 ش ، حدد دورة لمدة 5 أيام ، من 0.3 في المائة إلى 0.4 في المائة من المبلغ اليومي قفل المنتجات المالية ، ثم الانتاج اليومي على النحو التالي :",
		ad_sub_p2: "الحد الأدنى : 10000u س 0.3 في المائة = 30 ش",
		ad_sub_p3: "الحد الأقصى : 10000u × 0.4 في المائة = 40 ش",
		ad_sub_p4: "وهذا هو ، بعد 5 أيام يمكنك الحصول على 150u ~ 200u الإيرادات ، الإيرادات اليومية الصادرة ، والإيرادات الصادرة يمكن الوصول إليها في أي وقت ، بعد انتهاء قفل الرئيسية ، ونقل تلقائيا إلى حساب الأصول الخاصة بك .",
		ad_sub_p5: "إذا كنت ترغب في تحويل الرئيسية التي لم تنضج ، وسوف يكون هناك تعويضات مقطوعة ، تعويضات مقطوعة = نسبة التسوية الافتراضية * عدد الأيام المتبقية * عدد من الأقفال .",
		ad_sub_p6: "على سبيل المثال : نسبة خرق العقد تسوية هذا القفل هو 0.5 في المائة ، المتبقية 3 أيام تنتهي ، القفل هو 1000 ، ثم خرق العقد عقوبة = 0.5 في المائة * 3 * 1000 = 15 ش ، المبلغ الأصلي الفعلي هو 1000u-15u = 985u",
	},

	city: {
        albania: "ألبانيا",
        algeria: "الجزائر .",
        argentina: "الأرجنتين",
        armenia: "أرمينيا",
        australia: "أستراليا .",
        pakistan: "باكستان .",
        austria: "النمسا .",
        bahrain: "البحرين .",
        belgium: "بلجيكا",
        bosnia_and_Herzegovina: "البوسنة والهرسك",
        brazil: "البرازيل .",
        brunei: "بروناي",
        bulgaria: "بلغاريا",
        cambodia: "كمبوديا",
        canada: "كندا .",
        cameroon: "جمهورية الكونغو الديمقراطية",
        chile: "شيلي",
        colombia: "كولومبيا",
        costa_Rica: "كوستاريكا",
        croatia: "كرواتيا",
        cyprus: "قبرص",
        czech_Republic: "جمهورية التشيك",
        denmark: "الدانمرك",
        dominican_Republic: "الجمهورية الدومينيكية",
        egypt: "مصر .",
        estonia: "إستونيا",
        ethiopia: "إثيوبيا",
        finland: "فنلندا",
        france: "فرنسا .",
        georgia: "جورجيا",
        germany: "ألمانيا",
        ghana: "غانا",
        greece: "اليونان .",
        guyana: "غيانا",
        honduras: "المكسيك",
        hong_Kong_China: "هونغ كونغ ، الصين",
        hungary: "هنغاريا",
        iceland: "آيسلندا",
        ireland: "أيرلندا",
        italy: "إيطاليا .",
        india: "الهند .",
        indonesia: "إندونيسيا .",
        israel: "إسرائيل .",
        iran: "ایران",
        iraq: "العراق .",
        japan: "اليابان .",
        kazakstan: "كازاخستان",
        kenya: "كينيا",
        korea: "جمهورية كوريا",
        kuwait: "الكويت .",
        kyrgyzstan: "قيرغيزستان",
        laos: "لاوس",
        latvia: "لاتفيا",
        lithuania: "ليتوانيا",
        luxembourg: "لكسمبرغ",
        macao_China: "الصين ماكاو",
        macedonia: "مقدونيا",
        malaysia: "ماليزيا .",
        malta: "مالطة",
        mexico: "المكسيك .",
        moldova: "مولدوفا",
        monaco: "موناكو",
        mongolia: "منغوليا",
        montenegro: "الجبل الأسود",
        morocco: "المغرب .",
        myanmar: "ميانمار",
        netherlands: "هولندا .",
        new_Zealand: "نيوزيلندا",
        nepal: "نيبال",
        nigeria: "نيجيريا",
        norway: "النرويج",
        oman: "عُمان",
        palestine: "فلسطين .",
        panama: "بنما",
        paraguay: "باراغواي",
        peru: "بيرو",
        philippines: "الفلبين",
        poland: "بولندا",
        portugal: "البرتغال",
        puerto_Rico: "بورتوريكو",
        qatar: "قطر .",
        romania: "رومانيا",
        russia: "روسيا .",
        republic_of_Trinidad_and_Tobago: "ترينيداد وتوباغو",
        rwanda: "رواندا",
        saudi_Arabia: "المملكة العربية السعودية",
        serbia: "صربيا",
        singapore: "سنغافورة .",
        slovakia: "الجمهورية التشيكية",
        slovenia: "سلوفينيا",
        south_Africa: "جنوب أفريقيا",
        spain: "أسبانيا",
        sri_Lanka: "سري لانكا",
        sweden: "سويسرا",
        switzerland: "سويسرا",
        taiwan_China: "الصين تايوان",
        tajikistan: "باكستان",
        tanzania: "تنـزانيا",
        thailand: "تايلند .",
        turkey: "تركيا .",
        turkmenistan: "تركمانستان",
        ukraine: "أوكرانيا .",
        united_Arab_Emirates: "الإمارات العربية المتحدة",
        united_Kingdom: "المملكة المتحدة",
        united_States: "و { غ",
        uzbekistan: "أوزبكستان",
        venezuela: "فنـزويلا",
        vietnam: "فييت نام",
        afghanistan: "أفغاني",
        angola: "أنغولا",
        azerbaijan: "أذربيجان",
        bangladesh: "بنغلاديش",
        belarus: "بيﻻروس",
        belize: "بليز",
        benin: "بنن",
        bhutan: "بوتان",
        bolivia: "بوليفيـا",
        botswana: "بوتسوانا",
        british_Virgin_Islands: "جزر فيرجن البريطانية",
        burkina_Faso: "بوركينا فاسو",
        burundi: "بوروندي",
        cape_Verde: "الرأس الأخضر",
        cayman_Islands: "جزر كايمان",
        central_African_Republic: "جمهورية أفريقيا الوسطى",
        chad: "جمهورية الكونغو الديمقراطية",
        comoros: "جزر القمر",
        the_Republic_of_the_Congo: "الكونغو ( برازافيل )",
        democratic_Republic_of_the_Congo: "جمهورية الكونغو الديمقراطية",
        djibouti: "جيبوتي",
        ecuador: "إكوادور",
        el_Salvador: "السلفادور",
        equatorial_Guinea: "غينيا الاستوائية",
        eritrea: "أريتريا",
        fiji: "فيجي",
        gabon: "غابون",
        gambia: "غامبيا",
        greenland: "غرينلاند",
        guatemala: "غواتيمالا",
        guinea: "غينيا",
        haiti: "هايتي",
        isle_of_Man: "جزيرة مان",
        cote_d_Ivoire: "كوت ديفوار",
        jamaica: "جامايكا",
        jordan: "الأردن .",
        lebanon: "لبنان .",
        lesotho: "ليسوتو",
        liberia: "ليبيريا",
        libya: "ليبيا .",
        madagascar: "مدغشقر",
        malawi: "ملاوي",
        maldives: "جزر المالديف",
        mali: "جمهورية الكونغو الديمقراطية",
        mauritania: "موريتانيا",
        mauritius: "موريتانيا",
        mozambique: "موزامبيق",
        namibia: "ناميبيا",
        nicaragua: "نيكاراغوا",
        republic_of_Niger: "النيجر",
        north_Korea: "جمهورية كوريا الشعبية الديمقراطية",
        reunion: "ريونيون",
        san_Marino: "سان مارينو",
        senegal: "جمهورية الكونغو الديمقراطية",
        sierra_Leone: "سيراليون",
        somalia: "الصومال",
        sudan: "سوداني",
        suriname: "سورينام",
        eswatini: "سوازيلند",
        syria: "سوري .",
        togo: "توغو",
        tonga: "تونغا",
        tunisia: "تونس ،",
        united_States_Virgin_Islands: "جزر فيرجن التابعة للولايات المتحدة",
        uganda: "أوغندا",
        uruguay: "أوروغواي",
        vatican: "الفاتيكان",
        yemen: "اليمن .",
        yugoslavia: "يوغوسلافيا",
        zambia: "زامبيا",
        zimbabwe: "زمبابوي",
        china: "الصين .",
    }
};
