<template>
  <router-view></router-view>
</template>
<script>

export default {
  mounted() {
    // if (!this._isMobile()) {
    //   window.location.href = "https://www.mtmexc.com/";
    //   console.log('手机端')
    // }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  }
}

</script>
<style lang="less">
body {
  --bg-theme-green: #20b16c;
  --bg-theme-red: #ef454a;
  --bg-theme-yellow: #ffc900;
  --text-color1: #71757a;
  --color-green: #20b16c;
  --color-red: #ef454a;
  --color-yellow: #ffc900;

  --bg-white: #FFFFFF;
  --color-dark: #000000;
  --line-color-grey1: rgba(153, 153, 153, 0.1);
  --color-up: #149552;
  --color-down: #DB2F4A;
  --color-main: #036BF2;
  --color-theme-main: #036BF2;
  --color-b1: #006CF6;
  --color-b2: #0067EA;
  --color-b4: #006CF699;
  --color-b5: #006CF63D;

  --font-color1: #BDBDBD;
  --font-color2: #FC6924;
  --font-color3: #85868A;
  --font-color4: #4F8CCD;
  --bg-up: #EBF7F3;
  --bg-down: #FEBCCA;
  --bg-main: #EBF2FD;
  --bg-down3: rgba(254, 188, 202, .3);
  --bg-color1: #ECECEC;
  --bg-color2: #F6F6F6;
  --bg-color3: #ECF2FE;
  --bg-color4: #F5F8FF;
  --bg-color5: #E6EBFE;
  --bg-color6: #F6F6F6;
  --bg-color7: #BEBEBE;
  --bg-color8: #FC2421;
  --bg-color9: #FBAD14;
  --bg-color10: #ECAE77;
  --color-theme-main: #036BF2;

  --theme-bg-color: #F2F3F7;
  --color-theme-green: #149552;
  --color-theme-red: #DB2F4A;
  --color-theme-grey: #999999;
  --color-theme-orange: #F56B2E;
  --color-theme-warning: #E6A23C;
  --color-theme-success: #67C23A;
  --bg-theme-grey: rgba(153, 153, 153, 0.1);
  // --bg-img-card: url(/static/image/card-bg.png);
  --bg-card-green: #F1FFFA;
}
.el-divider {
  background-color: rgb(62, 63, 65) !important;
  margin: 16px 0;
}
.line-8 {
  background: black;
  height: 8px;
}

.el-table {
  background: transparent !important;

  &::before {
    height: 0 !important;
  }

  tr {
    background: transparent !important;
  }

  th.el-table__cell {
    color: #797d82 !important;
    font-size: 12px;
    background: transparent !important;
  }

  td.el-table__cell,
  th.el-table__cell.is-leaf {
    border-bottom: none !important;
    color: #FFF;
  }

 tr:hover>td.el-table__cell {
  background: transparent !important;
 }
}
</style>
