export default {
    addnew: {
		messageNotice: "Dear customer, hello! Please check your new information.",
		messages: "My Message",
		completed: "Abgeschlossen",
		position: "In Position",
		all: "Vollständig",
		submit: "Vorlegen",
        realFirst: "Bitte verifizieren Sie zunächst Ihre Identität",
        payFirst: "Bitte legen Sie zuerst das Abhebungspasswort fest",
        password1: "Passwort ändern",
        password2: "Passwort ändern",
        paypassTit1: "Bitte legen Sie Ihr Abhebungspasswort fest",
        oldPass: "Geben Sie das ursprüngliche ATM-Passwort ein",
        allView: "Alle lesen",
        confirmOrder: "Bestellung bestätigen",
        name: "Name",
        fee: "Bearbeitungsgebühr",
        orderCycle: "Zeit auswählen",
        loan: "Einen Kredit aufnehmen",
        loanTit: "Studentenkredit",
        loanHostory: "Darlehensunterlagen",
        labelProductTitle: "Produktname",
        immediateRepayment: "Sofortige Rückzahlung",
        loadAmount: "Erwarteter Darlehensbetrag",
        RepaymentCycle: "Zyklus der Darlehensrückzahlung",
        day: "Himmel",
        dailyRate: "Tageszinssatz",
        repaymentMethod: "Rückzahlungsmethode",
        lendingCompany: "Ausleihendes Unternehmen",
        loanTip: "Kredite (bitte stellen Sie sicher, dass die Bilder gut sichtbar sind)",
        loanTip1: "Eigentumsnachweis hochladen",
        loanTip2: "Einkommensnachweis (Arbeitsverhältnis)",
        loanTip3: "Angaben zum Kontoauszug",
        loanTip4: "Foto der Vorderseite Ihres Personalausweises hochladen",
        interest: "Sake",
        repaid: "Zurückgezahlt",
        unpaid: "Nicht-Rückzahlung",
        tab1: "Münzen",
        tab2: "Vertraglich",
        tab3: "Optionen",
        available: "Verfügbarer Kredit",
        pending: "Sperre",
        equivalent: "Betragen",
        fundTransfer: "Geldtransfer",
        flashExchange: "Bargeld",
        select_breed: "Währung auswählen",
        from: "Durch",
        to: "Bis",
        transferQuantity: "Anzahl der Überweisungen",
        repaymentMethod1:"Zur Rückzahlung in einer Rate fällig"
    },
	addNew: {
		place_card_back: "Klicken Sie hier, um ein Foto von der Rückseite Ihres Personalausweises hochzuladen",
		side1: "Auftragsverwaltung",
		side2: "Passwort ändern",
		side3: "Sprache",
		side4: "Log out of login",
		product: "Produkt",
		direction: "Richtung",
		cycle: "Transaktionsperiode",
		long_buy: "Buy long",
		short_sell: "Short sell",
		open_time: "Öffnungszeiten",
		close_time: "Closing Time",
		open_price: "Eröffnungspreis",
		close_price: "Closing price",
		number: "Menge",
		profit_loss: "Gewinn und Verlust",
		commission: "Bearbeitungsgebühr",
		account_type: "Kontowechseltyp",
		type1: "Münzkonto",
		type2: "Legal Account",
		type3: "Vertragskonto",
		type4: "Optionskonto",
		type5: "Wealth account",
		message1: "Bitte geben Sie den Betrag ein",
		message2: "Unzureichender Kontostand",
		place_transPsd: "Please enter transaction password",
		countpath: "Nach dem Countdown automatisch zur Bestellseite springen",
		nowpath: "Sofort springen",
	},
    common: {
		app_name: "Coinbase",
		confirm: "Erkennen",
		cancel: "Stornierungen",
		more: "Mehr",
		main: "Hauptbild",
		sub: "Untertitel",
		empty: "Keine Daten verfügbar",
		success: "Erfolge",
		copy_success: "Erfolg kopieren！",
		copy_error: "Fehlgeschlagene Fortpflanzung！",
		passed: "Genehmigt",
		rejectrd: "Abgelehnt",
		in_review: "In Prüfung",
		nodata: "Im Moment kein Inhalt",
		delete: "Entfernen",
		add: "Zusätzlich",
		buy: "Kaufen",
		sell: "Auftrumpfen",
		max: "Größte",
		all: "Vollständig",
		logout:"Sind Sie sicher, dass Sie sich abmelden möchten?",
		walletLink: "Bitte öffnen Sie diese Verbindung in Ihrem Geldbeutel!",
		connectWallet: "Wallet verbinden",
	},
	home: {
		nav1: "Teilen",
		nav2: "Geldbörsen",
		nav3: "Kurznachrichten",
		nav4: "Tutorials",
		nav5: "Erste Hilfe",
		nav6: "Bergbau",
		nav7: "Firmen",
		quick_p1: "Quick Fill Münze",
		quick_p2: "Unterstützt TRC20-USDT, ERC20-USDT, etc.",
		sort_tab1: "Anstieg und Rückgang der Aktie an einem Handelstag",
		sort_tab2: "24H Volumen",
		sort_tit: "Markt",
		sort_price: "Letzte Preise",
		sort_rate: "24-Stunden",
		sort_vol: "Umsatz",

	},
	form: {
		place_username_email: "Bitte geben Sie Ihren Benutzernamen/E-Mail ein",
		place_password: "Bitte geben Sie Ihr Passwort ein",
		place_confirm_password: "Bitte geben Sie Ihr Passwort erneut ein",
		place_email: "Bitte geben Sie Ihre E-Mail Adresse ein",
		send_email: "E-Mail",
		place_email_captcha: "Bitte geben Sie den E-Mail-Verifizierungscode ein",
		place_username: "Bitte geben Sie Ihren Benutzernamen ein",
		place_yzm: "Bitte geben Sie den Verifizierungscode ein",
		place_invite: "Bitte geben Sie die ID des Eingeladenen ein",
		place_inner: "Bitte Inhalt eingeben",
		place_select: "Bitte auswählen",
		label_oldpwd: "Original-Passwort",
		place_oldpwd: "Bitte geben Sie das ursprüngliche Anmeldepasswort ein",
		label_newpwd: "Neues Login-Passwort",
		place_newpwd: "Bitte geben Sie ein neues Login-Passwort ein",
		label_crmpwd: "Bestätigen Sie das neue Anmeldepasswort",
		place_crmpwd: "Bitte geben Sie das neue Login-Passwort erneut ein",
		place_country: "Bitte wählen Sie ein Land",
		place_name: "Bitte geben Sie Ihren Namen ein",
		place_card_number: "Bitte geben Sie Ihre ID-Nummer oder Passnummer ein",
		place_card_main: "Klicken Sie hier, um ein Foto der Vorderseite Ihres Personalausweises hochzuladen",
		label_currency: "Währung",
		place_currency: "Bitte wählen Sie eine Währung",
		label_account_number: "Kontonummer",
		place_account_number: "Bitte geben Sie Ihre Kontonummer ein",
		message_buy_amount: "Bitte geben Sie den Kaufbetrag ein",
		place_buy_tip: "Kaufen Sie mindestens 100",
	},

	foot: {
		nav1: "Homepage",
		nav2: "Die aktuelle",
		nav3: "Geschäfte",
		nav4: "Verbindlichk",
	},
	account: {
		login_title: "Willkommen bei",
		register_title: "Willkommen bei der Registrierung",
		contact: "Klicken Sie, um den Kundendienst zu kontaktieren",
		login: "Sich anmelden",
		no_account: "Sie haben noch kein Konto.？",
		free_register: "Kostenlose Registrierung",
		welcome_register: "Willkommen bei der Registrierung",
	},
	asset: {
		asset: "Verbindlichk",
		total_asset: "Gesamtvermögen",
		deposit: "Aufladen",
		withdraw: "Geld abheben",
		available: "Benutzerfreundlichkeit",
		freeze: "Einfrieren",
		total: "(Gesamt-)Summe",
		wallet: "Geldbörsen",
		select_link: "Wählen Sie einen primären Link",
		deposit_address: "Adresse aufladen",
		deposit_tip: "Bitte fügen Sie keine anderen Vermögenswerte als USDT zu dieser Adresse hinzu, alle anderen Vermögenswerte, die zu dieser Adresse hinzugefügt werden, können nicht wiederhergestellt werden.",
	},
	chart: {
		now: "Aktuell",
		open_price: "Eröffnungspreis",
		trans_amount: "Verkaufssumme",
		volumes: "Umsatz",
		height: "Oberste",
		low: "Niedrigste",
		buy: "Einkaufen",
		sell: "Verkauf",
		buy_more: "Bestechung",
		sell_short: "Ohne Deckung verkaufen",
		buy_long: "Long gehen (in einem Markt)",
		short_sell: "Leerverkauf",
		spots: "Ware auf Lager",
		foreign_exchange: "Fremdwährungsumtausch",
		market: "Die aktuelle",
		order_tab1: "Beteiligungen",
		order_tab2: "Ausstehender Auftrag",
		order_tab3: "Geschlossene Position",
		surplus: "Salden",
		available_balance: "Verfügbarer Saldo",
		net_value: "Nettowert",
		advance_charge: "Vorauszahlung",
		unrealized_profits: "Nicht profitabel",
		open_price2: "Eröffnungspreis",
		now_price: "Aktueller Preis",
		lever_title: "Hebelwirkung",
		lever: "Hebeleisen",
		check_price: "Preisobergrenze",
		market_price: "Marktwert",
		number: "Mengen",
		total_amount: "Gesamtbetrag",
		price: "Preise",
		now_entrust: "Laufende Kommissionen",
		history_entrust: "Historische Kommissionen",
		holding_positions: "Position halten",
		warehouse: "Stallhaltung",
		margin: "Einschuss",
		from: "Durch (eine Lücke)",
		to: "Bis",
		pay: "Bezahlen (Geld)",
		balance: "Salden",
		get: "Erhalten.",
		flash_exchange: "Bargeld",
		hand_number: "Ordnungszahl",
		registration_price: "Preis für ausstehende Aufträge",
		stop_profit: "Gewinn mitnehmen",
		stop_loss: "Stop-Loss",
		need_margin: "Pfand erforderlich",
		current_price: "Gegenwartswert",
		direction: "Ausrichtungen",
		currency_trade: "Devisenhandel",
		delivery_time: "Lieferzeit (linker Schieber für höhere Erträge)",
		second: "Aweite",
		buy_amount: "Einkaufssumme",
		fee: "Bearbeitungsgebühr",
		confirm_order: "Bestellung bestätigen",
		trade_history: "Geschichte des Handels",
		turnover_number: "Anzahl der Transaktionen",
		turnovered: "Verkauft",
		opened: "Noch zu besetzende Stelle",
		progress: "Zu",
		ended: "Geschlossen",
		revoked: "Zurückgezogen",
		complete: "Erfüllen.",
		time: "Mal",
		operate: "Rigg",
		closing_position: "Eine Position schließen",
		buy_price: "Angebotspreis",
	},
	market: {
		currency_trade: "Devisenhandel",
		spots: "Ware auf Lager",
		contract: "Vertraglich",
		adjustment_accounts: "Transferkredite",
	},
	table: {
		market: "Markt",
		rate: "24-Stunden",
		open_price: "Eröffnungspreis",
		last_price: "Neuester Preis",
	},
	my: {
		modify_login_pwd: "Login-Passwort ändern",
		company: "Firmen",
		help_center: "Hilfe-Center",
		history: "Geschichte des Devisenhandels",
		buy: "Einkaufen",
		sell: "Erreichen",
		buy_price: "Ankaufspreis",
		type: "Typologie",
		amount: "Geldsumme",
		final_price: "Endpreis",
		profit_loss: "Wachsend und abnehmend",
		state: "Stand der Dinge",
		loss: "(Finanzieller) Verlust",
		profit: "Rentabilität",
		news: "Kurznachrichten",
		authentication: "Akkreditierung",
		service: "Erste Hilfe",
		asset: {
			amount_record: "Erfassung der Beträge",
			deposit_record: "Auflade-Datensatz",
			withdraw_record: "Rücknahme von Aufzeichnungen",
		},
		help: {
			p1: "Jederzeit und überall zu Ihrer Verfügung",
			h1: "Allgemeine Probleme",
		},
		share: {
			share: "Teilen",
			invite_link: "Einladungs-Link",
			copy_link: "Link kopieren",
		},
		wallet: {
			title: "Adresse der Brieftasche",
			account_number: "Kontonummer",
			add_address: "Neue Adresse",

		}
	},
	finance: {
		finance_tit: "Aussperrung Bergbau",
		finance_label1: "Erwartete Rückkehr heute",
		finance_label2: "Kumulierter Gewinn",
		finance_label3: "Gehostete Aufträge",
		subscribe: "Zum Kauf auffordern",
		day: "Tag",
		record: "Rekord (im Sport usw.)",
		deposit_amount: "Aufstockungsbetrag",
		received_amount: "Erhaltener Geldbetrag",
		exchange_rate: "Wechselkurse",
		ad_p1: "Bergbau",
		ad_p2: "Lock-in Mining erfolgt durch das Hosting von usdt auf der Plattform Supercomputing Power Miner in der Plattform Mining-Pool für den Abbau Einnahmen",
		join: "Ich möchte teilnehmen.",
		ad_p3: "Produkt-Highlights",
		ad_p4: "Ein Beispiel anführen",
		ad_tit1: "Bezahlung nach Aufwand",
		ad_tit2: "Täglich ausgestellt",
		ad_tit3: "Sicherheit",
		ad_tit4: "Laufende Einnahmen",
		ad_tit5: "Tagesgleiche Zinsen",
		ad_des1: "Dividendenzyklus",
		ad_des2: "Kontokorrentzinsen",
		ad_des3: "100-prozentige Sicherheit",
		ad_des4: "Urlaubsverdienst",
		ad_des5: "Nach erfolgreicher Einzahlung",
		ad_sub_tit1: "Berechnung des Ergebnisses",
		ad_sub_tit2: "Strafe (Gebühr)",
		ad_sub_p1: "Wenn ein Mitglied eine Position von 10.000U auf der Plattform sperrt und ein Finanzprodukt mit einer Laufzeit von 5 Tagen und einer täglichen Ausgabe von 0,3% bis 0,4% des gesperrten Betrags wählt, wird die tägliche Ausgabe wie folgt sein:",
		ad_sub_p2: "Minimum: 10000 U x 0,3% = 30 U",
		ad_sub_p3: "Maximum: 10000 U x 0,4% = 40 U",
		ad_sub_p4: "Das heißt, Sie können 150U~200U nach 5 Tagen erhalten, die Erträge werden täglich ausgegeben, auf die ausgegebenen Erträge kann jederzeit zugegriffen werden, und das gesperrte Kapital wird nach Ablauf automatisch auf Ihr Vermögenskonto übertragen.",
		ad_sub_p5: "Wenn Sie den ausstehenden Kapitalbetrag prolongieren möchten, fällt eine Ausfallgebühr an, die sich aus dem Prozentsatz der Ausfallabrechnung * Anzahl der verbleibenden Tage * Anzahl der gesperrten Positionen ergibt.",
		ad_sub_p6: "Beispiel: Die Standardabwicklungsquote für dieses Lockout-Mining beträgt 0,5 %, bei einer verbleibenden Restlaufzeit von 3 Tagen und einer Anzahl von Lockouts von 1000, dann ist die Standardabwicklungsquote = 0,5 %*3*1000=15U, und das tatsächlich erstattete Kapital beträgt 1000U-15U=985U",
	},
	city: {
        albania: "Albanien",
        algeria: "Algerien",
        argentina: "Argentinien",
        armenia: "Armenien",
        australia: "Australien",
        pakistan: "Pakistan",
        austria: "Österreich",
        bahrain: "Bahrain",
        belgium: "Belgien",
        bosnia_and_Herzegovina: "Bosnien und Herzegowina",
        brazil: "Brasilien",
        brunei: "Brunei",
        bulgaria: "Bulgarien",
        cambodia: "Kambodscha",
        canada: "Kanada",
        cameroon: "Kamerun",
        chile: "Chile",
        colombia: "Kolumbien",
        costa_Rica: "Costa Rica",
        croatia: "Kroatien",
        cyprus: "Zypern",
        czech_Republic: "Tschechische Republik",
        denmark: "Dänemark",
        dominican_Republic: "Dominikanische Republik",
        egypt: "Ägypten",
        estonia: "Estland",
        ethiopia: "Äthiopien",
        finland: "Finnland",
        france: "Frankreich",
        georgia: "Georgien",
        germany: "Deutschland",
        ghana: "Ghana",
        greece: "Griechenland",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hongkong, China",
        hungary: "Ungarn",
        iceland: "Island",
        ireland: "Irland",
        italy: "Italien",
        india: "Indien",
        indonesia: "Indonesien",
        israel: "Israel",
        iran: "Iran",
        iraq: "Irak",
        japan: "Japan",
        kazakstan: "Kasachstan",
        kenya: "Kenia",
        korea: "Korea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kirgisistan",
        laos: "Laos",
        latvia: "Lettland",
        lithuania: "Litauen",
        luxembourg: "Luxemburg",
        macao_China: "Macao, China",
        macedonia: "Mazedonien",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Mexiko",
        moldova: "Moldawien",
        monaco: "Monaco",
        mongolia: "Mongolei",
        montenegro: "Montenegro",
        morocco: "Marokko",
        myanmar: "Myanmar",
        netherlands: "Niederlande",
        new_Zealand: "Neuseeland",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Norwegen",
        oman: "Oman",
        palestine: "Palästina",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "Philippinen",
        poland: "Polen",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Katar",
        romania: "Rumänien",
        russia: "Russland",
        republic_of_Trinidad_and_Tobago: "Republik Trinidad und Tobago",
        rwanda: "Ruanda",
        saudi_Arabia: "Saudi-Arabien",
        serbia: "Serbien",
        singapore: "Singapur",
        slovakia: "Slowakei",
        slovenia: "Slowenien",
        south_Africa: "Südafrika",
        spain: "Spanien",
        sri_Lanka: "Sri Lanka",
        sweden: "Schweden",
        switzerland: "Schweiz",
        taiwan_China: "Taiwan, China",
        tajikistan: "Tadschikistan",
        tanzania: "Tansania",
        thailand: "Thailand",
        turkey: "Türkei",
        turkmenistan: "Turkmenistan",
        ukraine: "Ukraine",
        united_Arab_Emirates: "Vereinigte Arabische Emirate",
        united_Kingdom: "Vereinigtes Königreich",
        united_States: "Vereinigte Staaten",
        uzbekistan: "Usbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Aserbaidschan",
        bangladesh: "Bangladesch",
        belarus: "Weißrussland",
        belize: "Belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bolivien",
        botswana: "Botswana",
        british_Virgin_Islands: "Britische Jungferninseln",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Kap Verde",
        cayman_Islands: "Kaimaninseln",
        central_African_Republic: "Zentralafrikanische Republik",
        chad: "Tschad",
        comoros: "Komoren",
        the_Republic_of_the_Congo: "Republik Kongo",
        democratic_Republic_of_the_Congo: "Demokratische Republik Kongo",
        djibouti: "Dschibuti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Äquatorialguinea",
        eritrea: "Eritrea",
        fiji: "Fidschi",
        gabon: "Gabun",
        gambia: "Gambia",
        greenland: "Grönland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: "Isle of Man",
        cote_d_Ivoire: "Elfenbeinküste",
        jamaica: "Jamaika",
        jordan: "Jordanien",
        lebanon: "Libanon",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Libyen",
        madagascar: "Madagaskar",
        malawi: "Malawi",
        maldives: "Malediven",
        mali: "Mali",
        mauritania: "Mauretanien",
        mauritius: "Mauritius",
        mozambique: "Mosambik",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Niger",
        north_Korea: "Nordkorea",
        reunion: "Réunion",
        san_Marino: "San Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "Sudan",
        suriname: "Suriname",
        eswatini: "Eswatini",
        syria: "Syrien",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunesien",
        united_States_Virgin_Islands: "Amerikanische Jungferninseln",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatikanstadt",
        yemen: "Jemen",
        yugoslavia: "Jugoslawien",
        zambia: "Sambia",
        zimbabwe: "Simbabwe",
        china: "China",
    }
};
