import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/account/login'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/account/login'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/account/register'),
  },
  {
    path: '/check',
    name: 'check',
    component: () => import('@/views/home/check'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/web',
    name: 'web',
    component: () => import('@/views/home/web'),
  },
  {
    path: '/market',
    name: 'market',
    component: () => import('@/views/market/market'),
  },
  // {
  //   path: '/trade',
  //   name: 'trade',
  //   component: () => import('@/views/trade/trade'),
  // },
  {
    path: '/trade-line',
    name: 'trade-line',
    component: () => import('@/views/trade/trade-line'),
  },
  {
    path: '/trade-second',
    name: 'trade-second',
    component: () => import('@/views/trade/trade-second'),
  },
  {
    path: '/trade-currency',
    name: 'trade-currency',
    component: () => import('@/views/trade/trade-currency'),
  },
  {
    path: '/trade-contract',
    name: 'trade-contract',
    component: () => import('@/views/trade/trade-contract'),
  },
  {
    path: '/trade-convert',
    name: 'trade-convert',
    component: () => import('@/views/trade/trade-convert'),
  },
  {
    path: '/detail/second',
    name: 'detail-second',
    component: () => import('@/views/trade/detail-second'),
  },
  {
    path: '/history/history-second',
    name: 'history-second',
    component: () => import('@/views/trade/history-second'),
  },
  {
    path: '/history/history-currency',
    name: 'history-currency',
    component: () => import('@/views/trade/history-currency'),
  },
  {
    path: '/history/history-contract',
    name: 'history-contract',
    component: () => import('@/views/trade/history-contract'),
  },
  {
    path: '/asset',
    name: 'asset',
    component: () => import('@/views/asset/asset'),
  },
  {
    path: '/deposit',
    name: 'asset',
    component: () => import('@/views/asset/deposit'),
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: () => import('@/views/asset/withdraw'),
  },
  {
    path: '/asset/transfer',
    name: 'transfer',
    component: () => import('@/views/asset/transfer'),
  },
  {
    path: '/asset/fundTransfer',
    name: 'fundTransfer',
    component: () => import('@/views/asset/fundTransfer'),
  },
  {
    path: '/my/message',
    name: 'message',
    component: () => import('@/views/my/message'),
  },
  {
    path: '/my/message-details',
    name: 'message-details',
    component: () => import('@/views/my/message-details'),
  },
  {
    path: '/my/primary',
    name: 'my',
    component: () => import('@/views/my/primary'),
  },
  {
    path: '/my/historyOrder',
    name: 'my',
    component: () => import('@/views/my/historyOrder'),
  },
  {
    path: '/my/assetRecord',
    name: 'my',
    component: () => import('@/views/my/assetRecord'),
  },
  {
    path: '/my/wallet',
    name: 'my',
    component: () => import('@/views/my/wallet'),
  },
  {
    path: '/my/share',
    name: 'my',
    component: () => import('@/views/my/share'),
  },
  {
    path: '/my/service',
    name: 'my',
    component: () => import('@/views/my/service'),
  },
  {
    path: '/my/help',
    name: 'my',
    component: () => import('@/views/my/help'),
  },
  {
    path: '/my/article',
    name: 'my',
    component: () => import('@/views/my/article'),
  },
  {
    path: '/my/changePass',
    name: 'my',
    component: () => import('@/views/my/changePass'),
  },
  {
    path: '/my/changePay',
    name: 'my',
    component: () => import('@/views/my/changePay'),
  },
  {
    path: '/my/changeEmail',
    name: 'my',
    component: () => import('@/views/my/changeEmail'),
  },
  {
    path: '/news',
    name: 'my',
    component: () => import('@/views/my/news'),
  },
  {
    path: '/company',
    name: 'my',
    component: () => import('@/views/my/company'),
  },
  {
    path: '/finance',
    name: 'finance',
    component: () => import('@/views/finance/index'),
  },
  {
    path: '/finance/ad',
    name: 'finance_ad',
    component: () => import('@/views/finance/ad'),
  },
  {
    path: '/finance/transfer',
    name: 'transfer',
    component: () => import('@/views/finance/transfer'),
  },
  {
    path: '/miner',
    name: 'miner',
    component: () => import('@/views/miner/index'),
  },
  {
    path: '/miner/record',
    name: 'miner',
    component: () => import('@/views/miner/record'),
  },
  {
    path: '/miner/ad',
    name: 'miner',
    component: () => import('@/views/miner/ad'),
  },
  {
    path: '/miner/details',
    name: 'details',
    component: () => import('@/views/miner/details'),
  },
  {
    path: '/chart',
    name: 'chart',
    component: () => import('@/views/chart/chart'),
  },


]
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {

  return originalPush.call(this, location).catch(err => err)

}


const router = new VueRouter({
  routes
})

export default router
