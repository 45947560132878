export default {
	yuebao: {
		0: "Finance Balance",
		1: "Total Interest",
		2: "Last Time",
		3: "Transfer In",
		4: "Transfer Out",
		5: "Transfer",
		6: "Staking",
		7: "Buy Up",
		8: "Buy Down",
		9: "Confirm",
		10: "Recharge Voucher",
		11: "Change Pay Password",
		12: "Current Password",
		13: "New Password",
		14: "Confirm Password",
		15: "Email verification",
		16: "Identity verification",
		17: "Withdraw Amount",
	},
    addnew: {
		homeNotice: "Embark on your journey of digital currency",
		market1: "Coin",
		market2: "Metal",
		messageNotice: "Dear customer, hello! Please check your new information.",
		messages: "My Message",
		completed: "Completed",
		position: "In position",
		all: "All",
		submit: "Submit",
        realFirst: "Please perform identity authentication first",
        payFirst: "Please set the transaction password first",
        password1: "Change login password",
        password2: "Change withdrawal password",
        paypassTit1: "Please set your withdrawal password",
        oldPass: "Old password",
        allView: "All read",
        confirmOrder: "confirm an order",
        name: "Name",
        fee: "Fee",
        orderCycle: "Timing",
        loan: "Loan",
        loanTit: "Assistance loans",
        loanHostory: "Loan records",
        labelProductTitle: "Product Name",
        immediateRepayment: "Immediate repayment",
        loadAmount: "Expected loan amount",
        RepaymentCycle: "Loan repayment cycle",
        day: "Day",
        dailyRate: "Daily Rate",
        repaymentMethod: "Repayment method",
        lendingCompany: "Loan company",
        loanTip: "Credit loan (please make sure the image is clear and visible)",
        loanTip1: "Upload property certificate",
        loanTip2: "Proof of income (employment relationship)",
        loanTip3: "Bank statement details",
        loanTip4: "Upload a photo of the front of your ID card",
        interest: "Interest",
        repaid: "Repaid",
        unpaid: "Unpaid",
        tab1: "Crypto",
        tab2: "Contract",
        tab3: "Option",
        available: "Available limit",
        pending: "Lock",
        equivalent: "Equivalent",
        fundTransfer: "Deposit",
        flashExchange: "Flash exchange",
        select_breed: "Select Currency",
        from: "From",
        to: "To",
        transferQuantity: "Transfer quantity",
        repaymentMethod1: "One repayment upon maturity"
    },
	addNew: {
		place_card_back: "Click to upload a photo of the back of your ID card",
		side1: "Order Management",
		side2: "Change password",
		side3: "Language",
		side4: "Log out",
		product: "Product",
		direction: "Direction",
		cycle: "Transaction period",
		long_buy: "Buy long",
		short_sell: "Short sell",
		open_time: "Opening time",
		close_time: "Closing Time",
		open_price: "Opening price",
		close_price: "Closing price",
		number: "Quantity",
		profit_loss: "Profit and Loss",
		commission: "Handling fee",
		account_type: "Account type",
		type1: "Spot",
		type2: "Fiat",
		type3: "Contract",
		type4: "Option",
		type5: "Finance",
		message1: "Please enter the amount",
		message2: "Insufficient account balance",
		place_transPsd: "Please enter transaction password",
		countpath: "Automatically jump to the order page after the countdown ends",
		nowpath: "Jump immediately",
	},
    common: {
		app_name: "Coinbase",
		confirm: "Confirm",
		cancel: "Abolish",
		more: "More",
		main: "Main picture",
		sub: "Subtitle",
		empty: "No data available",
		success: "Successes",
		copy_success: "Copy Success！",
		copy_error: "Reproduction Failure！",
		passed: "Approved",
		rejectrd: "Rejected",
		in_review: "Under review",
		nodata: "No content at this time",
		delete: "Removing",
		add: "Additional",
		buy: "Buy",
		sell: "Sell",
		max: "Greatest",
		all: "Full",
		logout:"Are you sure you want to log out？",
		walletLink: "Please open this connection in your wallet！",
		connectWallet: "Connect wallet",
	},
	home: {
		nav1: "Share",
		nav2: "Purses",
		nav3: "Newsflash",
		nav4: "Tutorials",
		nav5: "Customer Service",
		nav6: "Defi Mining",
		nav7: "Intoduction",
		quick_p1: "Fast Coin Deposit",
		quick_p2: "Supports TRC20-USDT, ERC20-USDT, etc.",
		sort_tab1: "Rate",
		sort_tab2: "Volume",
		sort_tit: "Market",
		sort_price: "Latest",
		sort_rate: "Rate",
		sort_vol: "Turnover",
	},
	form: {
		place_username_email: "Please enter your email",
		place_password: "Please enter your password",
		place_confirm_password: "Please enter your password again",
		place_email: "Please enter your e-mail address",
		send_email: "Mail",
		place_email_captcha: "Please enter the e-mail verification code",
		place_username: "Please enter your user name",
		place_yzm: "Please enter the verification code",
		place_invite: "Please enter the invitee ID",
		place_inner: "Please enter content",
		place_select: "Please select",
		label_oldpwd: "Original password",
		place_oldpwd: "Please enter the original login password",
		label_newpwd: "New Login Password",
		place_newpwd: "Please enter a new login password",
		label_crmpwd: "Confirm new login password",
		place_crmpwd: "Please enter your new login password again",
		place_country: "Please select a country",
		place_name: "Please enter your name",
		place_card_number: "Please enter your ID number or passport number",
		place_card_main: "Click to upload the front photo of your ID card",
		label_currency: "Money",
		place_currency: "Please select currency",
		label_account_number: "Account Number",
		place_account_number: "Please enter your account number",
		message_buy_amount: "Please enter the purchase amount",
		place_buy_tip: "Buy at least ",
	},

	foot: {
		nav1: "Home",
		nav2: "Market",
		nav3: "Trade",
		nav4: "Assets",
	},
	account: {
		login_title: "Welcome to",
		register_title: "Welcome to register",
		contact: "Click to contact customer service",
		login: "Log in",
		no_account: "Don't have an account yet.？",
		free_register: "Free Registration",
		welcome_register: "Welcome to register",
	},
	asset: {
		asset: "Assets",
		total_asset: "Total assets",
		deposit: "Deposit",
		withdraw: "Withdraw funds",
		available: "Usability",
		freeze: "Freeze ",
		total: "Total",
		wallet: "Wallet",
		select_link: "Selecting the primary link",
		deposit_address: "Recharge Address",
		deposit_tip: "Please do not add assets other than USDT to this address, any other assets added to this address will not be recovered.",
	},
	chart: {
		now: "Now",
		open_price: "Opening price",
		trans_amount: "Sum of money sold",
		volumes: "Turnover",
		height: "Supreme",
		low: "Lowest",
		buy: "Buy-in",
		sell: "Sell",
		buy_more: "Buy Long",
		sell_short: "Sell Short",
		buy_long: "Long (a position)",
		short_sell: "Short selling",
		spots: "Merchandise in stock",
		foreign_exchange: "Foreign (currency) exchange",
		market: "Market price",
		order_tab1: "Holdings",
		order_tab2: "Pending order",
		order_tab3: "Closed position",
		surplus: "Balances",
		available_balance: "Available Balance",
		net_value: "Net value",
		advance_charge: "Prepayment",
		unrealized_profits: "No profit realized",
		open_price2: "Opening price",
		now_price: "Current price",
		lever_title: "Leveraging",
		lever: "Lever",
		check_price: "Limit on price",
		market_price: "Market value",
		number: "Quantities",
		total_amount: "Total amount",
		price: "Prices",
		now_entrust: "Current commissions",
		history_entrust: "Historical commissions",
		holding_positions: "Hold position",
		warehouse: "Barnstorming",
		margin: "Margin",
		from: "From",
		to: "To",
		pay: "Pay",
		balance: "Balance",
		get: "Capture",
		flash_exchange: "Cash",
		hand_number: "Ordinal number",
		registration_price: "Pending order price",
		stop_profit: "Take profit",
		stop_loss: "Stop-loss",
		need_margin: "Deposit required",
		current_price: "Present value",
		direction: "Orientations",
		currency_trade: "Options Trading",
		delivery_time: "Delivery time",
		second: "Second (of time)",
		buy_amount: "Buy-in amount",
		fee: "Commission",
		confirm_order: "Submit",
		trade_history: "Trading History",
		turnover_number: "Number of transactions",
		turnovered: "Sold",
		opened: "Pending position opening",
		progress: "Progress",
		ended: "Closed",
		revoked: "Withdrawn",
		complete: "Fulfillment",
		time: "Timing",
		operate: "Manipulate",
		closing_position: "Close a position",
		buy_price: "Buy price",
	},
	market: {
		currency_trade: "Options",
		spots: "Spots",
		contract: "Contract",
		adjustment_accounts: "Transfer",
	},
	table: {
		market: "Market",
		rate: "Rate",
		open_price: "Opening",
		last_price: "Latest",
	},
	my: {
		modify_login_pwd: "Change Login Password",
		company: "Firms",
		help_center: "Help Center",
		history: "History of currency trading",
		buy: "Buy",
		sell: "Sell",
		buy_price: "Buying price",
		type: "Typology",
		amount: "Sum of money",
		final_price: "Final price",
		profit_loss: "Waxing and waning",
		state: "State of affairs",
		loss: "Loss",
		profit: "Profitability",
		news: "Newsflash",
		authentication: "Registration",
		service: "First aid",
		asset: {
			amount_record: "Amount recorded",
			deposit_record: "Deposit Record",
			withdraw_record: "Withdrawal Records",
		},
		help: {
			p1: "At your service anytime, anywhere",
			h1: "Common problems",
		},
		share: {
			share: "Share",
			invite_link: "Invitation Links",
			copy_link: "Copy link",
		},
		wallet: {
			title: "Wallet Address",
			account_number: "Account Number",
			add_address: "New address",
		}
	},
	finance: {
		finance_tit: "Lockout mining",
		finance_label1: "Expected return",
		finance_label2: "Cumulative gain",
		finance_label3: "Hosted orders",
		subscribe: "Ask to buy",
		day: "Day",
		record: "Record",
		deposit_amount: "Recharge amount",
		received_amount: "Amount",
		exchange_rate: "Exchange rates",
		ad_p1: "Mining",
		ad_p2: "Lock-in mining is by hosting usdt to the platform supercomputing power mining machine in the platform mining pool for mining revenue",
		join: "I want to participate.",
		ad_p3: "Product Highlights",
		ad_p4: "Give an example",
		ad_tit1: "Pay as you go",
		ad_tit2: "Issued daily",
		ad_tit3: "Safety",
		ad_tit4: "Ongoing revenue",
		ad_tit5: "Same day interest",
		ad_des1: "Dividend cycle",
		ad_des2: "Interest on demand",
		ad_des3: "100% Safe",
		ad_des4: "Holiday earnings",
		ad_des5: "After successful deposit",
		ad_sub_tit1: "Profit",
		ad_sub_tit2: "Penalty (fee)",
		ad_sub_p1: "If a member locks a position of 10,000U in the platform and chooses a financial product with a period of 5 days and a daily output of 0.3% to 0.4% of the amount of the locked position, the daily output will be as follows:",
		ad_sub_p2: "Minimum: 10,000 U x 0.3% = 30 U",
		ad_sub_p3: "Maximum: 10000 U x 0.4% = 40 U",
		ad_sub_p4: "That is, you can get 150U~200U after 5 days, the earnings are issued daily, the issued earnings can be accessed at any time, and the locked-in principal will be automatically transferred to your asset account upon expiration.",
		ad_sub_p5: "If you wish to roll over the outstanding principal amount, you will incur a default fee, which = Default Settlement Percentage * Number of Days Remaining * Number of Locked Positions.",
		ad_sub_p6: "Example: the default settlement ratio for this lockout mining is 0.5%, with 3 days remaining to expiration and a lockout quantity of 1000, the default = 0.5%*3*1000=15U, and the actual principal refunded is 1000U-15U=985U",
	},
    city: {
        albania: "Albania",
        algeria: "Algeria",
        argentina: "Argentina",
        armenia: "Armenia",
        australia: "Australia",
        pakistan: "Pakistan",
        austria: "Austria",
        bahrain: "Bahrain",
        belgium: "Belgium",
        bosnia_and_Herzegovina: "Bosnia and Herzegovina",
        brazil: "Brazil",
        brunei: "Brunei",
        bulgaria: "Bulgaria",
        cambodia: "Cambodia",
        canada: "Canada",
        cameroon: "Cameroon",
        chile: "Chile",
        colombia: "Colombia",
        costa_Rica: "Costa Rica",
        croatia: "Croatia",
        cyprus: "Cyprus",
        czech_Republic: "Czech Republic",
        denmark: "Denmark",
        dominican_Republic: "Dominican Republic",
        egypt: "Egypt",
        estonia: "Estonia",
        ethiopia: "Ethiopia",
        finland: "Finland",
        france: "France",
        georgia: "Georgia",
        germany: "Germany",
        ghana: "Ghana",
        greece: "Greece",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, China",
        hungary: "Hungary",
        iceland: "Iceland",
        ireland: "Ireland",
        italy: "Italy",
        india: "India",
        indonesia: "Indonesia",
        israel: "Israel",
        iran: "Iran",
        iraq: "Iraq",
        japan: "Japan",
        kazakstan: "Kazakhstan",
        kenya: "Kenya",
        korea: "Korea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kyrgyzstan",
        laos: "Laos",
        latvia: "Latvia",
        lithuania: "Lithuania",
        luxembourg: "Luxembourg",
        macao_China: "Macao_China",
        macedonia: "Macedonia",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Mexico",
        moldova: "Moldova",
        monaco: "Monaco",
        mongolia: "Mongolia",
        montenegro: "Montenegro",
        morocco: "Morocco",
        myanmar: "Myanmar",
        netherlands: "Netherlands",
        new_Zealand: " New_Zealand",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Norse",
        oman: "Oman",
        palestine: "Palestine",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "Philippines",
        poland: "Poland",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Qatar",
        romania: "Romania",
        russia: "Russia",
        republic_of_Trinidad_and_Tobago: "Republic of Trinidad and Tobago",
        rwanda: "Rwanda",
        saudi_Arabia: "Saudi Arabia",
        serbia: "Serbia",
        singapore: "Singapore",
        slovakia: "Slovakia",
        slovenia: "Slovenia",
        south_Africa: "South Africa",
        spain: "Spain",
        sri_Lanka: "Sri Lanka",
        sweden: "Sweden",
        switzerland: "Switzerland",
        taiwan_China: "Taiwan_China",
        tajikistan: "Tajikistan",
        tanzania: "Tanzania",
        thailand: "Thailand",
        turkey: "Turkey",
        turkmenistan: "Turkmenistan",
        ukraine: "Ukraine",
        united_Arab_Emirates: "United Arab Emirates",
        united_Kingdom: "United Kingdom",
        united_States: " United_States",
        uzbekistan: "Uzbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaijan",
        bangladesh: "Bangladesh",
        belarus: "Belarus",
        belize: "Belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bolivia",
        botswana: "Botswana",
        british_Virgin_Islands: "British Virgin Islands",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Cape Verde",
        cayman_Islands: "Cayman Islands",
        central_African_Republic: "Central African Republic",
        chad: "Chad",
        comoros: "Comoros",
        the_Republic_of_the_Congo: " The_Republic_of_the_Congo",
        democratic_Republic_of_the_Congo: "Democratic Republic of the Congo",
        djibouti: "Djibouti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Equatorial Guinea",
        eritrea: "Eritrea",
        fiji: "Fiji",
        gabon: "Gabon",
        gambia: "Gambia",
        greenland: "Greenland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: " Isle_of_Man",
        cote_d_Ivoire: "Cote_d_Ivoir",
        jamaica: "Jamaica",
        jordan: "Jordan",
        lebanon: "Lebanon",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Libya",
        madagascar: "Madagascar",
        malawi: "Malawi",
        maldives: "Maldives",
        mali: "Mali",
        mauritania: "Mauritania",
        mauritius: "Mauritius",
        mozambique: "Mozambique",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Republic_of_Niger",
        north_Korea: " North_Korea",
        reunion: "Reunion",
        san_Marino: "SAN Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "Sudan",
        suriname: "Suriname",
        eswatini: "Eswatini",
        syria: "Syria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisia",
        united_States_Virgin_Islands: "United States Virgin Islands",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatican",
        yemen: "Yemen",
        yugoslavia: "Yugoslavia",
        zambia: "Zambia",
        zimbabwe: "Zimbabwe",
        china: "China",
     }
};
