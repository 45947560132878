export default {
    addnew: {
		messageNotice: "Dear customer, hello! Please check your new information.",
		messages: "My Message",
		completed: "সম্পূর্ণ",
		position: "অবস্থান",
		all: "Whole",
		submit: "পাঠাও",
        realFirst: "অনুগ্রহ করে প্রথমে পরিচয় অনুমোদন করুন",
        payFirst: "প্রথমে পাসওয়ার্ড প্রত্যাহার করুন",
        password1: "লগ-ইন পাসওয়ার্ড পরিবর্তন করুন",
        password2: "পাসওয়ার্ড প্রত্যাহার করুন",
        paypassTit1: "অনুগ্রহ করে আপনার পাসওয়ার্ড নির্ধারণ করুন",
        oldPass: "পাসওয়ার্ড লিখুন",
        allView: "সব পড়া হয়েছে",
        confirmOrder: "আদেশ নিশ্চিত করো",
        name: "নাম",
        fee: "হ্যান্ডিং ফিস",
        orderCycle: "সময় নির্বাচন করুন",
        loan: "Loan",
        loanTit: "ঋণ সাহায্য করা হচ্ছে",
        loanHostory: "লোড রেকর্ড",
        labelProductTitle: "প্রক্রিয়ার নাম",
        immediateRepayment: "দ্রুত পুরস্কার",
        loadAmount: "অপ্রত্যাশিত লোনের পরিমাণ",
        RepaymentCycle: "লোয়ান পুনরুদ্ধার সাইকেল",
        day: "দিন",
        dailyRate: "প্রতিদিন আগ্রহের হার",
        repaymentMethod: "প্রতিক্রিয়া পদ্ধতি",
        lendingCompany: "লোয়ান কোম্পানি",
        loanTip: "ক্রিডিট ঋণ (অনুগ্রহ করে নিশ্চিত করুন যে ছবি পরিষ্কার এবং দৃশ্যমান)",
        loanTip1: "Upload property certificate",
        loanTip2: "আয়ের প্রমাণ (চাকুরি সম্পর্ক)",
        loanTip3: "ব্যাংক বিবৃতির বিস্তারিত বিবরণ",
        loanTip4: "আপনার ID কার্ডের সামনে একটি ছবি আপলোড করুন",
        interest: "আগ্রহ",
        repaid: "Repaid",
        unpaid: "বেতন নেই",
        tab1: "কয়েন",
        tab2: "চুক্তি",
        tab3: "অপশন",
        available: "বিদ্যমান ক্রেড",
        pending: "লক করা হচ্ছে",
        equivalent: "Equivalent",
        fundTransfer: "ফান্ড পরিবর্তন",
        flashExchange: "ফ্ল্যাশ বিনিময়",
        select_breed: "মান নির্বাচন করুন",
        from: "থেকে",
        to: "To",
        transferQuantity: "পরিমাণ",
        repaymentMethod1:"Repayment once due"
    },
	addNew: {
		place_card_back: "আপনার ID কার্ডের পেছনের একটি ছবি আপলোড করতে ক্লিক করুন",
		side1: "আদেশ ব্যবস্থাপনা",
		side2: "পাসওয়ার্ড পরিবর্তন করো",
		side3: "ভাষা",
		side4: "লগ-ইন করুন",
		product: "প্রোডেক্ট",
		direction: "দিকে",
		cycle: "প্রতিযোগিতার সময়",
		long_buy: "দীর্ঘ কিনুন",
		short_sell: "সংক্ষিপ্ত বিক্রি করো",
		open_time: "সময় খোলো",
		close_time: "সময় বন্ধ করা হচ্ছে",
		open_price: "মূল্য খোলা হচ্ছে",
		close_price: "বন্ধ হার",
		number: "পরিমাণ",
		profit_loss: "প্রোফিট এবং ক্ষতি",
		commission: "হ্যান্ডিং ফিস",
		account_type: "অ্যাকাউন্ট পরিবর্তনের ধরন",
		type1: "কয়েন অ্যাকাউন্ট",
		type2: "মুদ্রা অ্যাকাউন্ট",
		type3: "পরিচিতি অ্যাকাউন্ট",
		type4: "Option account",
		type5: "সম্পদ ব্যবস্থাপনা অ্যাকাউন্ট",
		message1: "অনুগ্রহ করে মাপ লিখুন",
		message2: "অ্যাকাউন্টের ব্যবস্থা যথেষ্ট নয়",
		place_transPsd: "অনুগ্রহ করে পাসওয়ার্ড লিখুন",
		countpath: "গণনা শেষ হওয়ার পর স্বয়ংক্রিয়ভাবে নির্দেশ পাতায় লাফ দাও",
		nowpath: "তাড়াতাড়ি লাফাও",
	},
	billTypes: [
		{v: 101, name: 'রিচার্জ'},
		{v: 102, name: 'ডিপজিট'},
		{v: 103, name: 'ফ্রিজ'},
		{v: 104, name: 'ফ্রিজ'},
		{v: 105, name: 'ডিপজিট'},
		{v: 106, name: 'ডিপজিট'},
		{v: 201, name: 'আবহাওয়া ফ্রিজ'},
		{v: 202, name: 'আবহাওয়া'},
		{v: 203, name: 'আবহাওয়া সফল'},
		{v: 204, name: 'আবর্জনা ব্যর্থ'},
		{v: 205, name: 'আবহাওয়া ফিস'},
		{v: 206, name: 'স্থানান্তর করুন'},
		{v: 207, name: 'স্থানান্তর করুন'},
		{v: 208, name: 'মূল্যবান পরিবর্তন'},
		{v: 209, name: 'মান পরিবর্তন'},
		{v: 301, name: 'মান হ্যান্ডিং ফি'},
		{v: 302, name: 'আয় পরিচিতি'},
		{v: 303, name: 'পরিচিতি'},
		{v: 311, name: 'কেনার অপশন'},
		{v: 312, name: 'অপশন ফিরিয়ে আনে'},
		{v: 313, name: 'অপশন প্রত্যাখ্যান করা হবে'},
		{v: 314, name: 'অপশন হ্যান্ডিং ফি'},
		{v: 315, name: 'মূল্যবান ফ্রীস কেনা'},
		{v: 316, name: 'মূল্যবোধ কেনার অবদান'},
		{v: 317, name: 'কোয়েন কেনা ফিরে যাওয়া'},
		{v: 318, name: 'কইন গ্রহণ করা হয়েছে'},
		{v: 319, name: 'কইন গ্রহণ করা হয়েছে'},
		{v: 320, name: 'বরফের কয়েন বিক্রি করে'},
		{v: 321, name: 'মূল্যবিক্রি বিক্রি অবদান'},
		{v: 322, name: 'কয়েন বিক্রি ফিরে আসে'},
		{v: 323, name: 'কইন অ্যাকাউন্টে বিক্রি করেছে'},
		{v: 324, name: 'কইন অ্যাকাউন্টে বিক্রি করেছে'},
		{v: 325, name: 'মান হ্যান্ডিং ফি'},
		{v: 401, name: 'মেশিন যোগ করা হচ্ছে'},
		{v: 402, name: 'মেশিন ফিরে আসা'},
		{v: 403, name: 'কম মেশিনিয়ার আয়'},
		{v: 404, name: 'মেশিন থেকে প্রস্থান করা হচ্ছে'},
		{v: 405, name: 'মেশিন থেকে প্রস্থান করা হচ্ছে হ্যান্ডিং ফিস'},
		{v: 501, name: 'অন্ধ বাক্স কিনু'},
		{v: 502, name: 'অন্ধ বাক্স আয়'},
	],
	common: {
		app_name: "Coinbase",
		confirm: "নিশ্চিত করুন",
		cancel: "বাতিল করুন",
		more: "আরও",
		main: "প্রধান ছবি",
		sub: "সাবপ্ল্যান্ট",
		empty: "বর্তমানে কোন তথ্য উপস্থিত নেই",
		success: "সফল",
		copy_success: "কপি সফল!",
		copy_error: "কপি ব্যর্থ!",
		passed: "পাস করা হয়েছে",
		rejectrd: "অস্বীকার করা হয়েছে",
		in_review: "প্রত্যাখ্যানের নীচে",
		nodata: "বর্তমানে কোন উপাদান উপস্থিত নেই",
		delete: "মুছে ফেলুন",
		add: "নতুন যোগ",
		buy: "কিনু",
		sell: "বিক্রি করুন",
		max: "সর্বোচ্চ",
		all: "সম্পূর্ণ",
		logout: "আপনি কি নিশ্চিত যে আপনি লগইন করতে চান？",
		walletLink: "অনুগ্রহ করে আপনার ওয়ালেটে এই সংযোগ খোলুন！",
		connectWallet: "ওয়ালেট সংযোগ করো",
	},
	home: {
		nav1: "শেয়ার করুন",
		nav2: "ওয়ালেট",
		nav3: "দ্রুত খবর",
		nav4: "শিক্ষার্থীর",
		nav5: "স্বনির্বাচিত সার্ভিস",
		nav6: "মেশিন ছোট",
		nav7: "কোম্পানি",
		quick_p1: "দ্রুত কয়েন রিশারেজ",
		quick_p2: "TRC20-USDT, ERC20-USDT, ইত্যাদি সমর্থন করে",
		sort_tab1: "মূল্যবৃদ্ধি তালিকা",
		sort_tab2: "২৪ ঘন্টা বিনিময়ের ভলিম",
		sort_tit: "বাজার",
		sort_price: "সর্বশেষ দাম",
		sort_rate: "২৪ ঘন্টা বেড়ে",
		sort_vol: "টার্নভার",

	},
	form: {
		place_username_email: "অনুগ্রহ করে ব্যবহারকারীর নাম/ই-মেইল লিখুন",
		place_password: "অনুগ্রহ করে পাসওয়ার্ড লিখুন",
		place_confirm_password: "অনুগ্রহ করে পাসওয়ার্ড আবার লিখুন",
		place_email: "অনুগ্রহ করে আপনার ই-মেইল ঠিকানা লিখুন",
		send_email: "ই-মেইল পাঠানো হচ্ছে",
		place_email_captcha: "অনুগ্রহ করে ই-মেইল পরীক্ষা কোড লিখুন",
		place_username: "একটি ব্যবহারকারীর নাম লিখুন",
		place_yzm: "অনুগ্রহ করে চিহ্নিত কোড লিখুন",
		place_invite: "অনুগ্রহ করে আমন্ত্রণীকারীর ID লিখুন",
		place_inner: "অনুগ্রহ করে বিষয়বস্তু লিখুন",
		place_select: "অনুগ্রহ করে নির্বাচন করুন",
		label_oldpwd: "মূল লগ-ইন পাসওয়ার্ড",
		place_oldpwd: "অনুগ্রহ করে মূল লগ-ইন পাসওয়ার্ড লিখুন",
		label_newpwd: "নতুন লগ-ইন পাসওয়ার্ড",
		place_newpwd: "অনুগ্রহ করে নতুন লগ-ইন পাসওয়ার্ড লিখুন",
		label_crmpwd: "নতুন লগ-ইন পাসওয়ার্ড নিশ্চিত করুন",
		place_crmpwd: "অনুগ্রহ করে নতুন লগ-ইন পাসওয়ার্ড আবার লিখুন",
		place_country: "অনুগ্রহ করে একটি দেশ নির্বাচন করুন",
		place_name: "অনুগ্রহ করে আপনার নাম লিখুন",
		place_card_number: "অনুগ্রহ করে আপনার ID নাম্বার অথবা পাসপোর্ট নাম্বার লিখুন",
		place_card_main: "আপনার ID কার্ডের একটি সামনের ছবি আপলোড করার জন্য ক্লিক করুন",
		label_currency: "মূল্য",
		place_currency: "অনুগ্রহ করে মূল্য নির্বাচন করুন",
		label_account_number: "অ্যাকাউন্ট নাম্বার",
		place_account_number: "অনুগ্রহ করে আপনার অ্যাকাউন্ট নাম্বার লিখুন",
		message_buy_amount: "অনুগ্রহ করে ক্রয়ের পরিমাণ লিখুন",
		place_buy_tip: "অন্তত কিনুন",
	},

	foot: {
		nav1: "হোমপেজ",
		nav2: "বাজার",
		nav3: "বিনিময়",
		nav4: "সম্পত্তি",
	},
	account: {
		login_title: "স্বাগতম",
		register_title: "নিবন্ধনে স্বাগতম",
		contact: "সার্ভিসের সাথে যোগাযোগ করার জন্য ক্লিক করুন",
		login: "লগ-ইন",
		no_account: "এখনো কোন অ্যাকাউন্ট নেই?",
		free_register: "ফ্রি নিবন্ধন",
		welcome_register: "নিবন্ধনে স্বাগতম",
	},
	asset: {
		asset: "সম্পত্তি",
		total_asset: "সম্পত্তি",
		deposit: "রিচার্জ",
		withdraw: "আবহাওয়া",
		available: "বিদ্যমান",
		freeze: "ফ্রিজ",
		total: "মোট",
		wallet: "ওয়ালেট",
		select_link: "প্রধান চেইন নির্বাচন করুন",
		deposit_address: "পুনরায় ঠিকানা",
		deposit_tip: "অনুগ্রহ করে USDT ছাড়া অন্য কোন সম্পত্তি এই ঠিকানায় রিসার্ভ করা হবে না। এই ঠিকানায় রিপোস্ট করা অন্যান্য সম্পত্তি পুনরায় সুস্",
	},
	chart: {
		now: "বর্তমান",
		open_price: "মূল্য খোলা হচ্ছে",
		trans_amount: "পরিবর্তনের পরিমাণ",
		volumes: "টার্নভার",
		height: "সর্বোচ্চ",
		low: "সর্বনিম্ন",
		buy: "কিনু",
		sell: "বিক্রি করা হচ্ছে",
		buy_more: "আরো কিনুন",
		sell_short: "সংক্ষিপ্ত বিক্রি করো",
		buy_long: "অনেক দিন যাচ্ছে",
		short_sell: "সংক্ষিপ্ত বিক্রি করো",
		spots: "স্পোট বিষয়বস্তু",
		foreign_exchange: "বিদেশী বিনিময়",
		market: "বাজার",
		order_tab1: "অবস্থান",
		order_tab2: "নির্ধারিত অবস্থান",
		order_tab3: "বন্ধ অবস্থান",
		surplus: "মান",
		available_balance: "বিদ্যমান ব্যবস্থা",
		net_value: "net worth",
		advance_charge: "প্রাধান্য পুরস্কার",
		unrealized_profits: "অজানা লাভ",
		open_price2: "মূল্য খোলা হচ্ছে",
		now_price: "বর্তমান দাম",
		lever_title: "লেভারেজ পরিবর্তন করা হচ্ছে",
		lever: "লেভার",
		check_price: "মূল্য সীমা",
		market_price: "বাজারের দাম",
		number: "পরিমাণ",
		total_amount: "মোট পরিমাণ",
		price: "দাম",
		now_entrust: "বর্তমান কমিশন",
		history_entrust: "ঐতিহাসিক কমিশন",
		holding_positions: "অবস্থান ধরা হচ্ছে",
		warehouse: "Warehouse by warehouse",
		margin: "মার্জিন",
		from: "থেকে",
		to: "যাও",
		pay: "প্রতিদান",
		balance: "মান",
		get: "পাওয়া যাবে",
		flash_exchange: "ফ্ল্যাশ বিনিময়",
		hand_number: "হ্যান্ড গণনা",
		registration_price: "ইউনিটের দাম",
		stop_profit: "অতিরিক্ত বন্ধ করো",
		stop_loss: "ক্ষতি বন্ধ করো",
		need_margin: "ডিপজিট প্রয়োজন",
		current_price: "বর্তমান দাম",
		direction: "দিকে",
		currency_trade: "মুদ্রা বিনিময়ের",
		delivery_time: "ডেলিভারি সময়",
		second: "সেকেন্ড",
		buy_amount: "কেনার পরিমাণ",
		fee: "হ্যান্ডিং ফিস",
		confirm_order: "আদেশ নিশ্চিত করো",
		trade_history: "অনুসন্ধান ইতিহাস",
		turnover_number: "পরিবর্তনের পরিমাণ",
		turnovered: "সম্পূর্ণ বিনিময়",
		opened: "খোলা হবে",
		progress: "অগ্রগতি",
		ended: "শেষ",
		revoked: "পুনরুদ্ধারিত",
		complete: "সম্পূর্ণ",
		time: "সময়",
		operate: "অপারেশন",
		closing_position: "অবস্থান বন্ধ করা হচ্ছে",
		buy_price: "কেনার দাম",
	},
	market: {
		currency_trade: "মুদ্রা বিনিময়ের",
		spots: "স্পোট বিষয়বস্তু",
		contract: "চুক্তি",
		adjustment_accounts: "পরিবর্তনের",
	},
	table: {
		market: "বাজার",
		rate: "২৪ ঘন্টা বেড়ে",
		open_price: "মূল্য খোলা হচ্ছে",
		last_price: "সর্বশেষ দাম",
	},
	my: {
		modify_login_pwd: "লগ-ইন পাসওয়ার্ড পরিবর্তন করুন",
		company: "কোম্পানি",
		help_center: "সাহায্য কেন্দ্র",
		history: "মূল্যবান ট্রান্সিং ইতিহাস",
		buy: "কিনু",
		sell: "বিক্রি করা হচ্ছে",
		buy_price: "কেনার দাম",
		type: "ধরন",
		amount: "টাকা",
		final_price: "শেষ দাম",
		profit_loss: "প্রোফিট এবং ক্ষতি",
		state: "অবস্থা",
		loss: "ক্ষতি",
		profit: "লাভ",
		news: "দ্রুত খবর",
		authentication: "অনুমোদন",
		service: "স্বনির্বাচিত সার্ভিস",
		asset: {
			amount_record: "সংখ্যা রেকর্ড",
			deposit_record: "রেকর্ড পুনরায় সংরক্ষণ করো",
			withdraw_record: "রেকর্ডের সময় বাতিল করা",
		},
		help: {
			p1: "তোমাকে যেকোন সময় সেবা দিয়ে দিচ্ছি, যেখানেই",
			h1: "প্রায়শই প্রশ্ন করে",
		},
		share: {
			share: "শেয়ার করুন",
			invite_link: "আমন্ত্রণ লিঙ্ক",
			copy_link: "লিঙ্ক কপি করুন",
		},
		wallet: {
			title: "ওয়ালেট ঠিকানা",
			account_number: "অ্যাকাউন্ট নাম্বার",
			add_address: "ঠিকানা যোগ করো",

		}
	},
	finance: {
		finance_tit: "লক করা মিনিটিং",
		finance_label1: "প্রত্যাশিত আজকের প্রত্যাশা",
		finance_label2: "অভিযুক্ত আয়",
		finance_label3: "হোস্ট নির্দেশ",
		subscribe: "সাবস্ক্রিপশন",
		day: "দিন",
		record: "রেকর্ড",
		deposit_amount: "রিচার্জ টাকা",
		received_amount: "গ্রহণ করা হয়েছে টাকা",
		exchange_rate: "বিনিময় হার",
		ad_p1: "শেষ লাভের জন্য মনে হচ্ছে",
		ad_p2: "লক করা মিনিং হচ্ছে প্ল্যাটফর্মের মিনিটিং পুলের লাভের জন্য প্ল্যাটফর্মে সুপার কমিউটিং মেশিনের প্লাটফর্মে ইউএসডিটিকে বি",
		join: "আমি অংশগ্রহণ করতে চাই",
		ad_p3: "প্রোডেক্ট হাইলাইট",
		ad_p4: "একটি উদাহরণ দাও",
		ad_tit1: "প্রয়োজনীয় হিসেবে সংরক্ষণ এবং উদ্ধার করুন",
		ad_tit2: "প্রতিদিন বিতরণের",
		ad_tit3: "নিরাপত্তা পদ্ধতি",
		ad_tit4: "অনলাইন আয়",
		ad_tit5: "একই দিনে আগ্রহ শুরু করা হচ্ছে",
		ad_des1: "বিভক্ত করুন",
		ad_des2: "বর্তমান আগ্রহ",
		ad_des3: "100%নিরাপত্তা",
		ad_des4: "ছুটির আয়",
		ad_des5: "সফল ডিপোস্টের পর",
		ad_sub_tit1: "গণনা প্রত্যাবর্তন করুন",
		ad_sub_tit2: "চুক্তি ভঙ্গের জন্য শাস্তি",
		ad_sub_p1: "সদস্যরা প্ল্যাটফর্মে ১০০০০ ইউ-এর অবস্থান বন্ধ করে একটি সম্পদ ব্যবস্থাপনা পণ্য নির্বাচন করে ৫ দিনের সাইকেল এবং প্রতিদিনের আউটপুটের মধ্যে ০.",
		ad_sub_p2: "সর্বনিম্ন：10000U x 0.3%=30 U",
		ad_sub_p3: "সর্বোচ্চ: 10000U x 0. 4%=40 U",
		ad_sub_p4: "In 5 days, you can receive returns of 150U to 200U, which are distributed daily and can be accessed at any time. After the lock in principal expires, it will automatically be transferred to your asset account.",
		ad_sub_p5: "আপনি যদি অপ্রত্যাশিত প্রধান মানুষকে পরিবর্তন করতে চান, তাহলে শাস্তি প্রদান করা হবে, যাকে ডিফল্ট স্থানান্তরের হার হিসেবে হিসেবে গণ্য করা হবে *",
		ad_sub_p6: "উদাহরণস্বরূপ, যদি লক করা খনির জন্য ডিফল্ট স্থানান্তরের হার ০.",
	},

    city: {
        albania: "আলবেনিয়া",
        algeria: "আলজেরিয়া",
        argentina: "আর্জেন্টিনা",
        armenia: "আর্মেনিয়া",
        australia: "অস্ট্রেলিয়া",
        pakistan: "পাকিস্তান",
        austria: "অস্ট্রিয়া",
        bahrain: "বাহরাইন巴林",
        belgium: "বেলজিয়াম",
        bosnia_and_Herzegovina: "বোস্নিয়া এবং হার্জেগোভিনা",
        brazil: "ব্রাজিল",
        brunei: "ব্রুনেই",
        bulgaria: "বুলগেরিয়া",
        cambodia: "ক্যাম্বোডিয়া",
        canada: "কানাডা",
        cameroon: "ক্যামেরুন",
        chile: "চিলি",
        colombia: "কলোম্বিয়া",
        costa_Rica: "কোস্টা রিকা",
        croatia: "ক্রোয়েশিয়া",
        cyprus: "সাইপ্রাস",
        czech_Republic: "চেক প্রজাতন্ত্র",
        denmark: "ডেনমার্ক",
        dominican_Republic: "ডোমিনিকান প্রজাতন্ত্র",
        egypt: "মিশর",
        estonia: "এস্তোনিয়া",
        ethiopia: "ইথিওপিয়া",
        finland: "ফিল্ডান",
        france: "ফ্রান্স",
        georgia: "জর্জিয়া",
        germany: "জার্মানিName",
        ghana: "ঘানা",
        greece: "গ্রীস",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "হংকং, চীন",
        hungary: "হাঙ্গেরি",
        iceland: "আইসল্যান্ড",
        ireland: "আয়ারল্যান্ড",
        italy: "ইতালি",
        india: "ভারত",
        indonesia: "ইন্দোনেশিয়া",
        israel: "ইজরায়েল",
        iran: "ইরান",
        iraq: "ইরাক",
        japan: "জাপান",
        kazakstan: "কাজাখস্তান",
        kenya: "কেনিয়া",
        korea: "কোরিয়া",
        kuwait: "কুয়েত",
        kyrgyzstan: "কিরগিজস্তান",
        laos: "লাওস",
        latvia: "লাটভিয়া",
        lithuania: "লিথুয়েনিয়াName",
        luxembourg: "লুক্সেমবার্গ",
        macao_China: "মাকাও, চীন",
        macedonia: "মেসিডোনিয়াName",
        malaysia: "মালয়েশিয়া",
        malta: "মাল্টা",
        mexico: "মেক্সিকোName",
        moldova: "মলদোভা",
        monaco: "মোনাকো",
        mongolia: "মঙ্গোলিয়া",
        montenegro: "মন্টেনিগ্রো",
        morocco: "Morocco",
        myanmar: "মায়ানমার",
        netherlands: "নেদারল্যান্ড",
        new_Zealand: "নিউ জিল্যান্ড",
        nepal: "নেপাল",
        nigeria: "নাইজেরিয়া",
        norway: "Norway",
        oman: "ওমান",
        palestine: "ফিলিস্তিন",
        panama: "পানামা",
        paraguay: "প্যারাগুয়ে",
        peru: "পেরু",
        philippines: "ফিলিপাইন",
        poland: "পোল্যান্ড",
        portugal: "পর্তুগাল",
        puerto_Rico: "পুয়ের্টো রিকো",
        qatar: "কাতার",
        romania: "রোমানিয়া",
        russia: "রাশিয়াName",
        republic_of_Trinidad_and_Tobago: "ট্রিনিদাদ এবং টোবাগো",
        rwanda: "রুয়ান্ডা",
        saudi_Arabia: "সৌদি আরব",
        serbia: "সার্বিয়া",
        singapore: "সিঙ্গাপুর",
        slovakia: "স্লোভাকিয়াName",
        slovenia: "স্লোভেনিয়া",
        south_Africa: "দক্ষিণ আফ্রিকা",
        spain: "স্পেন",
        sri_Lanka: "শ্রীলংকা",
        sweden: "সুইডেনName",
        switzerland: "সুইজারল্যান্ড",
        taiwan_China: "তাইওয়ান, চীন",
        tajikistan: "তাজিকিস্তান",
        tanzania: "তাঞ্জানিয়া",
        thailand: "থাইল্যান্ড",
        turkey: "তুর্কি",
        turkmenistan: "Turkmenistan",
        ukraine: "ইউক্রেন",
        united_Arab_Emirates: "Constellation name (optional)",
        united_Kingdom: "ব্রিটেন",
        united_States: "যুক্তরাষ্ট্র",
        uzbekistan: "উজবেকিস্তান",
        venezuela: "ভেনিজুয়েলা",
        vietnam: "ভিয়েতনাম",
        afghanistan: "আফগানিস্তান",
        angola: "Angola",
        azerbaijan: "আজারবাইজান",
        bangladesh: "বাংলাদেশ",
        belarus: "বেলারুস",
        belize: "বেলিজ",
        benin: "বেনিনName",
        bhutan: "ভুটান",
        bolivia: "বোলিভিয়া",
        botswana: "বোটসোয়ানা",
        british_Virgin_Islands: "ব্রিটিশ কুকর্মী দ্বীপপুঞ্জ",
        burkina_Faso: "বার্কিনা ফাসো",
        burundi: "বুরুনি",
        cape_Verde: "কেপ ভার্ড",
        cayman_Islands: "কেইমান দ্বীপপুঞ্জ",
        central_African_Republic: "Central African Republic",
        chad: "চ্যাড",
        comoros: "কমোরোস",
        the_Republic_of_the_Congo: "কঙ্গো (ব্রাজাভিল)",
        democratic_Republic_of_the_Congo: "গণতান্ত্রিক প্রজাতন্ত্র কঙ্গো",
        djibouti: "জিবুটি",
        ecuador: "ইকুয়েডর",
        el_Salvador: "এল সালভাডোর",
        equatorial_Guinea: "Equatorial Guinea",
        eritrea: "ইরিত্রিয়া",
        fiji: "ফিজি",
        gabon: "গ্যাবোন",
        gambia: "গাম্বিয়া",
        greenland: "গ্রীনল্যান্ড",
        guatemala: "গুয়াতেমালা",
        guinea: "গিনিয়া",
        haiti: "হাইতি",
        isle_of_Man: "মানুষের আইল",
        cote_d_Ivoire: "Cote d'Ivoire",
        jamaica: "জামাইকা",
        jordan: "জর্ডান",
        lebanon: "লেবানন",
        lesotho: "লেসোথো",
        liberia: "লাইবেরিয়া",
        libya: "লিবিয়া",
        madagascar: "মাদাগাস্কার",
        malawi: "মালাউই",
        maldives: "মালদ্বীপ",
        mali: "মালিName",
        mauritania: "মৌরিতানিয়া",
        mauritius: "মৌরিটিয়াস",
        mozambique: "মোজাম্বিক",
        namibia: "নামিবিয়া",
        nicaragua: "নিকারাগুয়া",
        republic_of_Niger: "Niger",
        north_Korea: "উত্তর কোরিয়া",
        reunion: "রিয়োন দ্বীপ",
        san_Marino: "সান মারিনো",
        senegal: "সেনেগাল",
        sierra_Leone: "সায়েরা লেওন",
        somalia: "সোমালিয়া",
        sudan: "সুদান",
        suriname: "সুরিনাম",
        eswatini: "Eswatini",
        syria: "সিরিয়া",
        togo: "টোগো",
        tonga: "টঙ্গা",
        tunisia: "তিউনিশিয়া",
        united_States_Virgin_Islands: "মার্কিন ভার্জিন দ্বীপপুঞ্জ",
        uganda: "Uganda",
        uruguay: "উরুগুয়ে",
        vatican: "ভ্যাটিক্যান",
        yemen: "ইয়েমেন",
        yugoslavia: "ইউগোস্লাভিয়া",
        zambia: "জাম্বিয়া",
        zimbabwe: "জিম্বাবুয়ে",
        china: "চীন",
    }
};
