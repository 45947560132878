export default {
    addnew: {
		messageNotice: "Dear customer, hello! Please check your new information.",
		messages: "My Message",
		completed: "Completato",
		position: "In posizione",
		all: "Completo",
		submit: "Presentare",
        realFirst: "Si prega di verificare prima la propria identità",
        payFirst: "Si prega di impostare prima la password di prelievo",
        password1: "Modifica della password",
        password2: "Modifica della password",
        paypassTit1: "Impostare la password di prelievo",
        oldPass: "Inserire la password originale del bancomat",
        allView: "Tutti leggono",
        confirmOrder: "Confermare l'ordine",
        name: "Nome",
        fee: "Spese di gestione",
        orderCycle: "Selezionare l'ora",
        loan: "Ottenere un prestito ",
        loanTit: "Prestito studentesco",
        loanHostory: "Registri dei prestiti",
        labelProductTitle: "Nome del prodotto",
        immediateRepayment: "Rimborso immediato",
        loadAmount: "Importo previsto del prestito",
        RepaymentCycle: "Ciclo di rimborso del prestito",
        day: "Cielo",
        dailyRate: "Tasso di interesse giornaliero",
        repaymentMethod: "Metodo di rimborso",
        lendingCompany: "Società di prestito",
        loanTip: "Prestiti di credito (assicurarsi che le foto siano ben visibili)",
        loanTip1: "Caricare il certificato di proprietà",
        loanTip2: "Prova di reddito (rapporto di lavoro)",
        loanTip3: "Dettagli dell'estratto conto bancario",
        loanTip4: "Caricare la foto frontale della carta d'identità",
        interest: "Sake",
        repaid: "Restituito",
        unpaid: "Mancato pagamento",
        tab1: "Monete",
        tab2: "Contrattuale",
        tab3: "Opzioni",
        available: "Credito disponibile",
        pending: "Bloccare",
        equivalent: "Importo a",
        fundTransfer: "Trasferimento di fondi",
        flashExchange: "Contanti",
        select_breed: "Selezionare la valuta",
        from: "Attraverso ",
        to: "Fino a quando",
        transferQuantity: "Numero di trasferimenti",
        repaymentMethod1:"Con obbligo di rimborso in un'unica soluzione"
    },
	addNew: {
		place_card_back: "Fai clic per caricare una foto del retro della tua carta d'identità",
		side1: "Gestione degli ordini",
		side2: "Cambia password",
		side3: "Lingua",
		side4: "Esci e accedi",
		product: "Prodotto",
		direction: "Direzione",
		cycle: "Durante il periodo di transazione",
		long_buy: "Compra lungo",
		short_sell: "Vendite allo scoperto",
		open_time: "Orario aperto",
		close_time: "Orario di chiusura",
		open_price: "Apertura",
		close_price: "Close rate",
		number: "Quantità",
		profit_loss: "Utile e perdite",
		commission: "Spese di gestione",
		account_type: "Tipo di modifica del conto",
		type1: "Conto monete",
		type2: "Conto valuta Fiat",
		type3: "Conto contrattuale",
		type4: "Conto opzione",
		type5: "Conto di gestione patrimoniale",
		message1: "Inserisci l'importo",
		message2: "Saldo insufficiente del conto",
		place_transPsd: "Inserisci la password della transazione",
		countpath: "Salta automaticamente alla pagina dell'ordine dopo la fine del conto alla rovescia",
		nowpath: "Salta immediatamente",
	},
	billTypes: [
		{v: 101, name: 'Ricarica'},
		{v: 102, name: 'Deposito'},
		{v: 103, name: 'Congelare'},
		{v: 104, name: 'Scongelamento'},
		{v: 105, name: 'Deposito'},
		{v: 106, name: 'Deposito'},
		{v: 201, name: 'Congelamento di sospensione'},
		{v: 202, name: 'Ritiro'},
		{v: 203, name: 'Ritiro riuscito'},
		{v: 204, name: 'Ritiro fallito'},
		{v: 205, name: 'Spese di prelievo'},
		{v: 206, name: 'Trasferimento fuori'},
		{v: 207, name: 'Trasferimento in'},
		{v: 208, name: 'Trasferimento di valuta'},
		{v: 209, name: 'Trasferimento di valuta'},
		{v: 301, name: 'Tassa di gestione della valuta'},
		{v: 302, name: 'Redditi contrattuali'},
		{v: 303, name: 'Perdite contrattuali'},
		{v: 311, name: 'Opzioni di acquisto'},
		{v: 312, name: 'Restituisce opzione'},
		{v: 313, name: 'Rimborso dell opzione'},
		{v: 314, name: 'Tassa di gestione delle opzioni'},
		{v: 315, name: 'Congelamento dell acquisto di valuta'},
		{v: 316, name: 'Deduzione dell acquisto di valuta'},
		{v: 317, name: 'Rendimento dell acquisto di monete'},
		{v: 318, name: 'Acquisto di monete ricevute'},
		{v: 319, name: 'Acquisto di monete ricevute'},
		{v: 320, name: 'Vendita di monete congelate'},
		{v: 321, name: 'Detrazione delle vendite in valuta'},
		{v: 322, name: 'Rendimento delle vendite di monete'},
		{v: 323, name: 'Monete vendute sul conto'},
		{v: 324, name: 'Monete vendute sul conto'},
		{v: 325, name: 'Tassa di gestione della valuta'},
		{v: 401, name: 'Aggiunta di macchine minerarie'},
		{v: 402, name: 'Ritorno delle macchine minerarie'},
		{v: 403, name: 'Entrate delle macchine minerarie'},
		{v: 404, name: 'Uscita della macchina mineraria'},
		{v: 405, name: 'Tassa di gestione delle uscite delle macchine minerarie'},
		{v: 501, name: 'Acquisto di blind box'},
		{v: 502, name: 'Entrate della scatola blinda'},
	],
	common: {
		app_name: "Coinbase",
		confirm: "Conferma",
		cancel: "Annulla",
		more: "Di più",
		main: "Immagine principale",
		sub: "Sottoplot",
		empty: "Attualmente non ci sono dati disponibili",
		success: "Successo",
		copy_success: "Copia riuscita!",
		copy_error: "Copia fallita!",
		passed: "Passato",
		rejectrd: "Respinto",
		in_review: "In corso di revisione",
		nodata: "Attualmente non ci sono contenuti disponibili",
		delete: "Elimina",
		add: "Nuova aggiunta",
		buy: "Acquistare",
		sell: "Vendere",
		max: "Massimo",
		all: "Intero",
		logout: "Sei sicuro di voler disconnetterti？",
		walletLink: "Apri questa connessione nel tuo portafoglio！",
		connectWallet: "Collega portafoglio",
	},
	home: {
		nav1: "Quota",
		nav2: "Portafoglio",
		nav3: "Notizie rapide",
		nav4: "Tutorial",
		nav5: "Servizio clienti",
		nav6: "Estrazione mineraria",
		nav7: "Azienda",
		quick_p1: "Ricarica rapida delle monete",
		quick_p2: "Supporta TRC20-USDT, ERC20-USDT, ecc",
		sort_tab1: "Grafico della crescita",
		sort_tab2: "Volume di negoziazione 24 ore su 24",
		sort_tit: "Mercato",
		sort_price: "Ultimi prezzi",
		sort_rate: "24 ore",
		sort_vol: "Fatturato",

	},
	form: {
		place_username_email: "Inserisci nome utente/email",
		place_password: "Inserisci la password",
		place_confirm_password: "Inserisci nuovamente la tua password",
		place_email: "Inserisci il tuo indirizzo email",
		send_email: "Invio di e-mail",
		place_email_captcha: "Inserisci il codice di verifica e-mail",
		place_username: "inserisci un nome utente",
		place_yzm: "Inserisci il codice di verifica",
		place_invite: "Inserisci l'ID dell'invitato",
		place_inner: "Inserisci il contenuto",
		place_select: "Seleziona",
		label_oldpwd: "Password di accesso originale",
		place_oldpwd: "Inserisci la password di accesso originale",
		label_newpwd: "Nuova password di accesso",
		place_newpwd: "Inserisci una nuova password di accesso",
		label_crmpwd: "Conferma nuova password di accesso",
		place_crmpwd: "Inserisci di nuovo la nuova password di accesso",
		place_country: "Seleziona un paese",
		place_name: "Inserisci il tuo nome",
		place_card_number: "Inserisci il tuo numero d'identità o il numero di passaporto",
		place_card_main: "Clicca per caricare una foto frontale della tua carta d'identità",
		label_currency: "valuta",
		place_currency: "Seleziona la valuta",
		label_account_number: "Numero di conto",
		place_account_number: "Inserisci il tuo numero di conto",
		message_buy_amount: "Inserisci l'importo dell'acquisto",
		place_buy_tip: "Almeno compra",
	},

	foot: {
		nav1: "Homepage",
		nav2: "Mercato",
		nav3: "Transazione",
		nav4: "Asset",
	},
	account: {
		login_title: "Benvenuti a",
		register_title: "Benvenuto per registrarsi",
		contact: "Clicca per contattare il servizio clienti",
		login: "Login",
		no_account: "Non hai ancora un account?",
		free_register: "Registrazione gratuita",
		welcome_register: "Benvenuto per registrarsi",
	},
	asset: {
		asset: "Asset",
		total_asset: "Totale delle attività",
		deposit: "Ricarica",
		withdraw: "Ritiro",
		available: "Disponibile",
		freeze: "Congelare",
		total: "Totale",
		wallet: "Portafoglio",
		select_link: "Seleziona la catena principale",
		deposit_address: "Indirizzo di ricarica",
		deposit_tip: "Si prega di non ricaricare alcun patrimonio diverso da USDT a questo indirizzo. Eventuali altri beni depositati in questo indirizzo non saranno recuperabili",
	},
	chart: {
		now: "Corrente",
		open_price: "Apertura",
		trans_amount: "Importo dell'operazione",
		volumes: "Fatturato",
		height: "più alto",
		low: "minimo",
		buy: "Mompra",
		sell: "Vendita",
		buy_more: "Compra di più",
		sell_short: "Vendite allo scoperto",
		buy_long: "Andando lungo",
		short_sell: "Vendite allo scoperto",
		spots: "Merci spot",
		foreign_exchange: "Valuta estera",
		market: "Mercato",
		order_tab1: "Posizione",
		order_tab2: "Collocamento dell'ordine",
		order_tab3: "Posizione chiusa",
		surplus: "Saldo",
		available_balance: "Saldo disponibile",
		net_value: "Patrimonio netto",
		advance_charge: "Pagamento anticipato",
		unrealized_profits: "Profitti non realizzati",
		open_price2: "Apertura",
		now_price: "Prezzo corrente",
		lever_title: "Regolazione della leva finanziaria",
		lever: "Leva",
		check_price: "Limite di prezzo",
		market_price: "Prezzo di mercato",
		number: "Quantità",
		total_amount: "Importo totale",
		price: "Prezzo",
		now_entrust: "Commissione attuale",
		history_entrust: "Commissione storica",
		holding_positions: "Mantenimento di posizioni",
		warehouse: "Magazzino per magazzino",
		margin: "Margine",
		from: "Da",
		to: "a",
		pay: "Pagamento",
		balance: "Saldo",
		get: "Ottenere",
		flash_exchange: "Scambio flash",
		hand_number: "Numero delle mani",
		registration_price: "Prezzo unitario di sospensione",
		stop_profit: "Stop surplus",
		stop_loss: "Stop loss",
		need_margin: "Deposito richiesto",
		current_price: "Prezzo corrente",
		direction: "Direzione",
		currency_trade: "Operazioni in valuta",
		delivery_time: "Tempi di consegna",
		second: "Secondo",
		buy_amount: "Importo dell'acquisto",
		fee: "Spese di gestione",
		confirm_order: "Conferma ordine",
		trade_history: "Cronologia delle transazioni",
		turnover_number: "Quantità di transazione",
		turnovered: "Operazione completata",
		opened: "Da aprire",
		progress: "In corso",
		ended: "Terminato",
		revoked: "Revocato",
		complete: "Completo",
		time: "Tempo",
		operate: "Operazione",
		closing_position: "Posizione di chiusura",
		buy_price: "Prezzo d'acquisto",
	},
	market: {
		currency_trade: "Operazioni in valuta",
		spots: "Merci spot",
		contract: "Contratto",
		adjustment_accounts: "Adeguamento dei conti",
	},
	table: {
		market: "Mercato",
		rate: "24 ore",
		open_price: "Apertura",
		last_price: "Ultimo",
	},
	my: {
		modify_login_pwd: "Cambia password di accesso",
		company: "Azienda",
		help_center: "Centro assistenza",
		history: "Cronologia delle transazioni in valuta",
		buy: "Compra",
		sell: "Vendita",
		buy_price: "Prezzo d'acquisto",
		type: "Tipo",
		amount: "Denaro",
		final_price: "Prezzo finale",
		profit_loss: "Utile e perdite",
		state: "Stato",
		loss: "Perdita",
		profit: "Profitto",
		news: "Notizie rapide",
		authentication: "Autenticazione",
		service: "Servizio clienti",
		asset: {
			amount_record: "Record dell'importo",
			deposit_record: "Registri di ricarica",
			withdraw_record: "Registrazione dei prelievi",
		},
		help: {
			p1: "Fornire servizi sempre e ovunque",
			h1: "Domande frequenti",
		},
		share: {
			share: "Quota",
			invite_link: "Link di invito",
			copy_link: "Copia collegamento",
		},
		wallet: {
			title: "Indirizzo del portafoglio",
			account_number: "Numero di conto",
			add_address: "Aggiungi indirizzo",
		}
	},
	finance: {
		finance_tit: "Mining bloccato",
		finance_label1: "Entrate attese di oggi",
		finance_label2: "Entrate accumulate",
		finance_label3: "Ordini ospitati",
		subscribe: "Abbonamento",
		day: "Giorno",
		record: "Record",
		deposit_amount: "Importo di ricarica",
		received_amount: "Importo ricevuto",
		exchange_rate: "Tasso di cambio",
		ad_p1: "Mining per profitti infiniti",
		ad_p2: "Il mining bloccato è il processo di affidamento di USDT alle macchine minerarie di supercomputing della piattaforma per il mining dei profitti nel pool minerario della piattaforma",
		join: "Voglio partecipare",
		ad_p3: "Caratteristiche del prodotto",
		ad_p4: "Dare un esempio",
		ad_tit1: "Memorizza e recupera se necessario",
		ad_tit2: "Distribuzione giornaliera",
		ad_tit3: "Misure di sicurezza",
		ad_tit4: "Reddito continuo",
		ad_tit5: "Interessi iniziali lo stesso giorno",
		ad_des1: "Ciclo di pagamento dei dividendi",
		ad_des2: "Interessi correnti",
		ad_des3: "100% sicurezza",
		ad_des4: "Reddito da vacanza",
		ad_des5: "Dopo il deposito riuscito",
		ad_sub_tit1: "Calcolo delle entrate",
		ad_sub_tit2: "Pena per violazione del contratto",
		ad_sub_p1: "I membri bloccano una posizione di 10000U sulla piattaforma e scelgono un prodotto di gestione patrimoniale con un ciclo di 5 giorni e una produzione giornaliera dello 0,3% allo 0,4% dell'importo di lock up.",
		ad_sub_p2: "Minimo: 10000U x 0,3%=30 U",
		ad_sub_p3: "Massimo: 10000U x 0,4%=40 U",
		ad_sub_p4: "In 5 giorni, è possibile ricevere resi da 150U a 200U, distribuiti quotidianamente e accessibili in qualsiasi momento. Una volta scaduto il lock in principal, verrà automaticamente trasferito sul vostro conto asset.",
		ad_sub_p5: "Se si desidera trasferire il capitale non scaduto, verrà applicata una penale, che viene calcolata come il rapporto di regolamento predefinito * giorni rimanenti * numero di posizioni bloccate.",
		ad_sub_p6: "Ad esempio, se il rapporto di regolamento predefinito per il mining bloccato è dello 0,5%, e i restanti 3 giorni sono dovuti e il numero di posizioni bloccate è di 1000, la penale predefinita è dello 0,5% * 3 * 1000=15U e il principale restituito effettivo è 1000U-15U=985U",
	},

	city: {
		albania: "Albania",
		algeria: "Algeria",
		argentina: "Argentina",
		armenia: "Armenia",
		australia: "Australia",
		pakistan: "Pakistan",
		austria: "Austria",
		bahrain: "Bahrein",
		belgium: "Belgio",
		bosnia_and_Herzegovina: "Bosnia_Erzegovina",
		brazil: "Brasile",
		brunei: "Brunei",
		bulgaria: "Bulgaria",
		cambodia: "Cambogia",
		canada: "Canada",
		cameroon: "Camerun",
		chile: "Cile",
		colombia: "Colombia",
		costa_Rica: "Costa Rica",
		croatia: "Croazia",
		cyprus: "Cipro",
		czech_Republic: "Repubblica Ceca",
		denmark: "Danimarca",
		dominican_Republic: "Repubblica Dominicana",
		egypt: "Egitto",
		estonia: "Estonia",
		ethiopia: "Etiopia",
		finland: "Finlandia",
		france: "Francia",
		georgia: "Georgia",
		germany: "Germania",
		ghana: "Ghana",
		greece: "Grecia",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, Cina",
		hungary: "Ungheria",
		iceland: "Islanda",
		ireland: "Irlanda",
		italy: "Italia",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israele",
		iran: "Iran",
		iraq: "Iraq",
		japan: "Giappone",
		kazakstan: "Kazakistan",
		kenya: "Kenya",
		korea: "Corea",
		kuwait: "Kuwait",
		kyrgyzstan: "Kirghizistan",
		laos: "Laos",
		latvia: "Lettonia",
		lithuania: "Lituania",
		luxembourg: "Lussemburgo",
		macao_China: "Macao, Cina",
		macedonia: "Macedonia",
		malaysia: "Malaysia",
		malta: "Malta",
		mexico: "Messico",
		moldova: "Moldavia",
		monaco: "Monaco",
		mongolia: "Mongolia",
		montenegro: "Repubblica di Montenegro",
		morocco: "Marocco",
		myanmar: "Myanmar",
		netherlands: "Paesi Bassi",
		new_Zealand: "Nuova Zelanda",
		nepal: "Nepal",
		nigeria: "Nigeria",
		norway: "Norvegia",
		oman: "Oman",
		palestine: "Palestina",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Perù",
		philippines: "Filippine",
		poland: "Polonia",
		portugal: "Portogallo",
		puerto_Rico: "Puerto Rico",
		qatar: "Qatar",
		romania: "Romania",
		russia: "Russia",
		republic_of_Trinidad_and_Tobago: "Repubblica di Trinidad e Tobago",
		rwanda: "Ruanda",
		saudi_Arabia: "Arabia Saudita",
		serbia: "Serbia",
		singapore: "Singapore",
		slovakia: "Slovacchia",
		slovenia: "Slovenia",
		south_Africa: "Sudafrica",
		spain: "Spagna",
		sri_Lanka: "Sri Lanka",
		sweden: "Svezia",
		switzerland: "Svizzera",
		taiwan_China: "Taiwan, Cina",
		tajikistan: "Tagikistan",
		tanzania: "Tanzania",
		thailand: "Thailandia",
		turkey: "Türkiye",
		turkmenistan: "Turkmenistan",
		ukraine: "Ucraina",
		united_Arab_Emirates: "Emirati Arabi Uniti",
		united_Kingdom: "Gran Bretagna",
		united_States: "Stati Uniti",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaigian",
		bangladesh: "Bangladesh",
		belarus: "Bielorussia",
		belize: "Belize",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "Isole Vergini Britanniche",
		burkina_Faso: "burkina faso",
		burundi: "Burundi",
		cape_Verde: "Capo Verde",
		cayman_Islands: "Isole Cayman",
		central_African_Republic: "Repubblica Centrafricana",
		chad: "Ciad",
		comoros: "Comore",
		the_Republic_of_the_Congo: "Congo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Congo (Kinshasa)",
		djibouti: "Gibuti",
		ecuador: "Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Guinea Equatoriale",
		eritrea: "Eritrea",
		fiji: "Figi",
		gabon: "Gabon",
		gambia: "Gambia",
		greenland: "Groenlandia",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: "Isola di Man",
		cote_d_Ivoire: "Costa d'Avorio",
		jamaica: "Giamaica",
		jordan: "Giamaica",
		lebanon: "Libano",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libia",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldive",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Mauritius",
		mozambique: "Mozambico",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger",
		north_Korea: "Corea del Nord",
		reunion: "Réunion",
		san_Marino: "San Marino",
		senegal: "Senegal",
		sierra_Leone: "sierra leone",
		somalia: "Somalia",
		sudan: "Sudan",
		suriname: "Suriname",
		eswatini: "Eswatini",
		syria: "Siria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisia",
		united_States_Virgin_Islands: "Isole Vergini Americane",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vaticano",
		yemen: "Yemen",
		yugoslavia: "Jugoslavia",
		zambia: "Zambia",
		zimbabwe: "zimbabwe",
		china: "Cina",
	}
};
