export default {
	yuebao: {
		0: "財務残高",
		1: "利息総額",
		2: "前回",
		3: "転入",
		4: "転出",
		5: "乗り換え",
		6: "抵当に入れる",
		7: "買う",
		8: "買い取る",
		9: "確認",
		10: "Recharge Voucher",
		11: "Change Pay Password",
		12: "Current Password",
		13: "New Password",
		14: "Confirm Password",
		15: "Email verification",
		16: "Identity verification",
		17: "Withdraw Amount",
	},
    addnew: {
		homeNotice: "デジタル通貨の旅を始める",
		market1: "コイン枚",
		market2: "きんぞく",
		messageNotice: "尊敬するお客様、こんにちは！新しい情報を確認してください.",
		messages: "私のメッセージ",
		completed: "完了",
		position: "持倉中",
		all: "すべて",
		submit: "送信",
        realFirst: "最初に本人確認をお願いします。",
        payFirst: "出金パスワードを設定してください。",
        password1: "パスワードの変更",
        password2: "パスワードの変更",
        paypassTit1: "6桁の支払いパスワードを入力してください",
        oldPass: "旧パスワード",
        allView: "すべて読む",
        confirmOrder: "注文の確認",
        name: "な",
        fee: "手数料",
        orderCycle: "時間を選択",
        loan: "ローン",
        loanTit: "イネーブル・ローン",
        loanHostory: "ローン記録",
        labelProductTitle: "製品名",
        immediateRepayment: "即時返済",
        loadAmount: "融資予定額",
        RepaymentCycle: "ローン返済サイクル",
        day: "日",
        dailyRate: "日歩",
        repaymentMethod: "返済方法",
        lendingCompany: "ローン会社",
        loanTip: "クレジット機能（写真がはっきりと見えるようにしてください。）",
        loanTip1: "不動産証明書のアップロード",
        loanTip2: "収入証明書（雇用）",
        loanTip3: "銀行口座明細",
        loanTip4: "IDカードの正面写真をアップロードする",
        interest: "日本酒",
        repaid: "払い戻し",
        unpaid: "未払い",
        tab1: "通貨",
        tab2: "契約",
        tab3: "オプション",
        available: "残高",
        pending: "ロック",
        equivalent: "に達する。",
        fundTransfer: "漕ぎ回す",
        flashExchange: "閃兌",
        select_breed: "通貨を選択",
        from: "より",
        to: "まで",
        transferQuantity: "轉移數量",
        repaymentMethod1: "返済期限は1回"
    },
	addNew: {
		place_card_back: "身分証明書の裏面写真をアップロードする」をクリックして",
		side1: "オーダー管理",
		side2: "修改密碼",
		side3: "言語",
		side4: "ログオンを終了する",
		product: "製品",
		direction: "方向",
		cycle: "取引期間",
		long_buy: "たくさん買う",
		short_sell: "空売りをする",
		open_time: "開梱時間",
		close_time: "平倉時間",
		open_price: "オープン価格",
		close_price: "平倉価格",
		number: "數量",
		profit_loss: "損益",
		commission: "手数料",
		account_type: "帳簿変更タイプ",
		type1: "貨幣口座",
		type2: "通貨口座",
		type3: "契約口座",
		type4: "オプションアカウント",
		type5: "財テク口座",
		message1: "金額を入力してください",
		message2: "口座残高が不足している",
		place_transPsd: "取引パスワードを入力してください",
		countpath: "カウントダウン終了自動オーダーページへジャンプ",
		nowpath: "今すぐジャンプ",
	},
    common: {
		app_name: "Coinbase",
		confirm: "認識する",
		cancel: "キャンセル",
		more: "もっと見る",
		main: "メイン写真",
		sub: "サブタイトル",
		empty: "データなし",
		success: "成功",
		copy_success: "コピー成功！",
		copy_error: "生殖の失敗！",
		passed: "承認済み",
		rejectrd: "不採用",
		in_review: "検討中",
		nodata: "今のところ内容なし",
		delete: "除去",
		add: "追加",
		buy: "購入",
		sell: "見せびらかす",
		max: "最大",
		all: "全フル部",
		logout:"本当にログインしますか？",
		walletLink: "財布の中でこの接続を開けてください！",
		connectWallet: "ウォレットの接続",
	},
	home: {
		nav1: "分かち合う",
		nav2: "財布",
		nav3: "ニュースフラッシュ",
		nav4: "チュートリアル",
		nav5: "応急処置",
		nav6: "掘る挖矿",
		nav7: "ファームズ",
		quick_p1: "クイックフィルコイン",
		quick_p2: "TRC20-USDT、ERC20-USDTなどに対。",
		sort_tab1: "一日の株の上げ下げ",
		sort_tab2: "24時間ボリューム",
		sort_tit: "市場",
		sort_price: "最新価格",
		sort_rate: "24時間の上昇",
		sort_vol: "ターンオー",

	},
	form: {
		place_username_email: "ユーザー名/Eメールを入力してください",
		place_password: "パスワードを入力してください",
		place_confirm_password: "パスワードをもう一度入力してください。",
		place_email: "メールアドレスを入力してください",
		send_email: "郵便",
		place_email_captcha: "Eメール認証コードを入力してください。",
		place_username: "ユーザー名を入力してください",
		place_yzm: "認証コードを入力してください。",
		place_invite: "招待者IDを入力してください。",
		place_inner: "内容を入力してください",
		place_select: "選択してください",
		label_oldpwd: "オリジナルパスワード",
		place_oldpwd: "元のログインパスワードを入力してください。",
		label_newpwd: "新しいログインパスワード",
		place_newpwd: "新しいログインパスワードを入力してください",
		label_crmpwd: "新しいログインパスワードの確認",
		place_crmpwd: "新しいログインパスワードをもう一度入力してください。",
		place_country: "国を選択してください",
		place_name: "お名前を入力してください",
		place_card_number: "ID番号またはパスポート番号を入力してください。",
		place_card_main: "IDカードの正面写真をアップロードするにはクリックしてください。",
		label_currency: "通貨",
		place_currency: "通貨を選択してください",
		label_account_number: "口座番号",
		place_account_number: "口座番号を入力してください。",
		message_buy_amount: "購入金額を入力してください。",
		place_buy_tip: "最低100個購入",
	},

	foot: {
		nav1: "ホームページ",
		nav2: "市場状況行情",
		nav3: "取引",
		nav4: "負債",
	},
	account: {
		login_title: "ようこそ",
		register_title: "登録歓迎",
		contact: "カスタマーサービスへのお問い合わせはこちら",
		login: "サインイン",
		no_account: "まだアカウントを持っていない？",
		free_register: "無料登録",
		welcome_register: "登録歓迎",
	},
	asset: {
		asset: "負債",
		total_asset: "総資産",
		deposit: "リチャージ",
		withdraw: "資金を引き出す",
		available: "ユーザビリティ",
		freeze: "凍結",
		total: "合計",
		wallet: "財布",
		select_link: "プライマリリンクを選択する",
		deposit_address: "リチャージ住所",
		deposit_tip: "このアドレスにUSDT以外の資産を追加しないでください。このアドレスに追加された他の資産は回復できません。",
	},
	chart: {
		now: "対す",
		open_price: "オープニング価格",
		trans_amount: "売却金額",
		volumes: "ターンオー",
		height: "最高",
		low: "最低",
		buy: "買い付け",
		sell: "付く",
		buy_more: "賄賂",
		sell_short: "売り抜ける",
		buy_long: "張る",
		short_sell: "空売り",
		spots: "在庫商品",
		foreign_exchange: "がいこくかわせ",
		market: "現在の市場状況",
		order_tab1: "手持ち",
		order_tab2: "注文待ち",
		order_tab3: "クローズドポジション",
		surplus: "バランス",
		available_balance: "利用可能残高",
		net_value: "純額",
		advance_charge: "前払い",
		unrealized_profits: "採算が合わない",
		open_price2: "オープニング価格",
		now_price: "現在価格",
		lever_title: "レバレッジング",
		lever: "プライバー",
		check_price: "価格制限",
		market_price: "市場価値",
		number: "数量",
		total_amount: "総額",
		price: "物価",
		now_entrust: "現在のコミッション",
		history_entrust: "歴史的なコミッション",
		holding_positions: "ホールドポジション",
		warehouse: "バーンストーミング",
		margin: "証拠金",
		from: "をとおして",
		to: "まで",
		pay: "払う",
		balance: "バランス",
		get: "得る",
		flash_exchange: "現金",
		hand_number: "せりばんご",
		registration_price: "未決注文価格",
		stop_profit: "テイクプロフィット",
		stop_loss: "ストップロス",
		need_margin: "保証金要",
		current_price: "現在価値",
		direction: "オリエンテーション",
		currency_trade: "通貨取引",
		delivery_time: "納期（リターンが高い場合は左スライド）",
		second: "60分の1度に相当する角度または弧の単位",
		buy_amount: "買い付け金額",
		fee: "手数料",
		confirm_order: "注文の確認",
		trade_history: "取引履歴",
		turnover_number: "取引件数",
		turnovered: "販売済み",
		opened: "ポジションオープン待ち",
		progress: "に対して",
		ended: "クローズド",
		revoked: "引っ込み思案",
		complete: "果たす",
		time: "回",
		operate: "リグ",
		closing_position: "ポジションを閉じる",
		buy_price: "入札価格",
	},
	market: {
		currency_trade: "通貨取引",
		spots: "在庫商品",
		contract: "契約",
		adjustment_accounts: "単位移行",
	},
	table: {
		market: "市場",
		rate: "24時間の上昇",
		open_price: "オープニング価格",
		last_price: "最新価格",
	},
	my: {
		modify_login_pwd: "ログインパスワードの変更",
		company: "ファームズ",
		help_center: "ヘルプセンター",
		history: "為替取引の歴史",
		buy: "買い付け",
		sell: "付く",
		buy_price: "購入価格",
		type: "類型論",
		amount: "金額",
		final_price: "最終価格",
		profit_loss: "盛衰",
		state: "情勢",
		loss: "損失",
		profit: "収益性",
		news: "ニュースフラッシュ",
		authentication: "認定",
		service: "応急処置",
		asset: {
			amount_record: "金額の記録",
			deposit_record: "涵養記録",
			withdraw_record: "退会記録",
		},
		help: {
			p1: "いつでも、どこでも",
			h1: "一般的な問題",
		},
		share: {
			share: "分かち合う",
			invite_link: "招待リンク",
			copy_link: "リンクをコピーする",
		},
		wallet: {
			title: "財布の住所",
			account_number: "口座番号",
			add_address: "新住所",

		}
	},
	finance: {
		finance_tit: "ロックアウト・マイニング",
		finance_label1: "本日の帰国予定",
		finance_label2: "累積利益",
		finance_label3: "ホスト・オーダー",
		subscribe: "購入を申し込む",
		day: "日",
		record: "きろく",
		deposit_amount: "上乗せ額",
		received_amount: "受取金額",
		exchange_rate: "為替レート",
		ad_p1: "鉱業",
		ad_p2: "ロックインマイニングは、プラットフォームのスーパーコンピューティングパワーマイナーにusdtをホスティングすることによって行われ、プラットフォームマイニングプールでマイニング収益を得る。",
		join: "参加したい。",
		ad_p3: "製品ハイライト",
		ad_p4: "例を挙げる",
		ad_tit1: "ペイ・アズ・ユー・ゴー",
		ad_tit2: "毎日発行",
		ad_tit3: "安全性",
		ad_tit4: "継続的な収益",
		ad_tit5: "当日金利",
		ad_des1: "配当サイクル",
		ad_des2: "当座預金利息",
		ad_des3: "100％の安全性",
		ad_des4: "ホリデー収益",
		ad_des5: "入金成功後",
		ad_sub_tit1: "収益計算",
		ad_sub_tit2: "違約金",
		ad_sub_p1: "もし会員がプラットフォーム上で10,000Uのポジションをロックし、期間5日、1日の取引量がロックした金額の0.3%から0.4%の金融商品を選択した場合、1日の取引量は以下のようになります：",
		ad_sub_p2: "最小値：10000 U x 0.3% = 30 U",
		ad_sub_p3: "最大：10000 U x 0.4% = 40 U",
		ad_sub_p4: "つまり、5日後に150U~200Uを得ることができ、収益は毎日発行され、発行された収益にはいつでもアクセスすることができ、ロックされた元本は満期時に自動的に資産口座に振り込まれます。",
		ad_sub_p5: "未決済元本のロールオーバーを希望する場合、デフォルト手数料が発生します。この手数料は = デフォルト決済率 * 残存日数 * ロックポジション数です。",
		ad_sub_p6: "例：このロックアウトマイニングのデフォルトの決済比率は0.5%で、満期まで残り3日、ロックアウト回数が1000回の場合、デフォルト=0.5%*3*1000=15U、実際に払い戻される元金は1000U-15U=985Uとなります。",
	},
    city: {
		albania: "アルバニア",
		algeria: "アルジェリア",
		argentina: "アルゼンチン",
		armenia: "アルメニア",
		australia: "オーストラリア",
		pakistan: "パキスタン",
		austria: "オーストリア",
		bahrain: "オーストリア",
		belgium: "ベルギー",
		bosnia_and_Herzegovina: "ボスニア・ヘルツェゴビナ",
		brazil: "ブラジル",
		brunei: "ブルネイ",
		bulgaria: "ブルガリア",
		cambodia: "カンボジア",
		canada: "カナダ",
		cameroon: "カメルーン",
		chile: "チリ",
		colombia: "コロンビア",
		costa_Rica: "コスタリカ",
		croatia: "クロアチア",
		cyprus: "キプロス",
		czech_Republic: "チェコ共和国",
		denmark: "デンマーク",
		dominican_Republic: "ドミニカ共和国",
		egypt: "エジプト",
		estonia: "エストニア",
		ethiopia: "エチオピア",
		finland: "フィンランド",
		france: "フランス",
		georgia: "グルジア",
		germany: "ドイツ",
		ghana: "ガーナ",
		greece: "ギリシャ",
		guyana: "ガイアナ",
		honduras: "ホンジュラス",
		hong_Kong_China: "中国香港",
		hungary: "ハンガリー",
		iceland: "アイスランド",
		ireland: "アイルランド",
		italy: "イタリア",
		india: "インド",
		indonesia: "インドネシア",
		israel: "イスラエル",
		iran: "イラン",
		iraq: "イラク",
		japan: "日本",
		kazakstan: "カザフスタン",
		kenya: "ケニア",
		korea: "韓国",
		kuwait: "クウェート",
		kyrgyzstan: "キルギス",
		laos: "ラオス",
		latvia: "ラトビア",
		lithuania: "リトアニア",
		luxembourg: "ルクセンブルク",
		macao_China: "中国マカオ",
		macedonia: "マケドニア",
		malaysia: "マレーシア",
		malta: "マルタ",
		mexico: "メキシコ",
		moldova: "モルドバ",
		monaco: "モナコ",
		mongolia: "モンゴル",
		montenegro: "モンテネグロ共和国",
		morocco: "モロッコ",
		myanmar: "ミャンマー",
		netherlands: "オランダ",
		new_Zealand: "ニュージーランド",
		nepal: "ネパール",
		nigeria: "ナイジェリア",
		norway: "ノルウェー",
		oman: "オマーン",
		palestine: "パレスチナ",
		panama: "パナマ",
		paraguay: "パラグアイ",
		peru: "ペルー",
		philippines: "フィリピン",
		poland: "ポーランド",
		portugal: "ポルトガル",
		puerto_Rico: "プエルトリコ",
		qatar: "カタール",
		romania: "ルーマニア",
		russia: "ロシア",
		republic_of_Trinidad_and_Tobago: "トリニダード・トバゴ共和国",
		rwanda: "ルワンダ",
		saudi_Arabia: "サウジアラビア",
		serbia: "セルビア",
		singapore: "シンガポール",
		slovakia: "スロバキア",
		slovenia: "スロベニア",
		south_Africa: "南アフリカ共和国",
		spain: "スペイン",
		sri_Lanka: "スリランカ",
		sweden: "スウェーデン",
		switzerland: "スイス",
		taiwan_China: "中国台湾",
		tajikistan: "タジキスタン",
		tanzania: "タンザニア",
		thailand: "タイ",
		turkey: "トルコ",
		turkmenistan: "トルクメニスタン",
		ukraine: "ウクライナ",
		united_Arab_Emirates: "アラブ首長国連邦",
		united_Kingdom: "英国",
		united_States: "アメリカ",
		uzbekistan: "ウズベキスタン",
		venezuela: "ベネズエラ",
		vietnam: "ベトナム",
		afghanistan: "アフガニスタン",
		angola: "アンゴラ",
		azerbaijan: "アゼルバイジャン",
		bangladesh: "バングラデシュ",
		belarus: "ベラルーシ",
		belize: "ベリーズ",
		benin: "ベニン",
		bhutan: "ブータン",
		bolivia: "ボリビア",
		botswana: "ボツワナ",
		british_Virgin_Islands: "イギリス領ヴァージン諸島",
		burkina_Faso: "ブルキナファソ",
		burundi: "ブルンジ",
		cape_Verde: "カーボベルデ角",
		cayman_Islands: "ケイマン諸島",
		central_African_Republic: "中央アフリカ共和国",
		chad: "チャド",
		comoros: "コモロ",
		the_Republic_of_the_Congo: "コンゴ（布）",
		democratic_Republic_of_the_Congo: "コンゴ（金）",
		djibouti: "ジブチ",
		ecuador: "エクアドル",
		el_Salvador: "エルサルバドル",
		equatorial_Guinea: "赤道ギニア",
		eritrea: "エリトリア",
		fiji: "フィジー",
		gabon: "ガボン",
		gambia: "ガンビア",
		greenland: "グリーンランド",
		guatemala: "グアテマラ",
		guinea: "ギニア",
		haiti: "ハイチ",
		isle_of_Man: "マーン島",
		cote_d_Ivoire: "コートジボワール",
		jamaica: "ジャマイカ",
		jordan: "ヨルダン",
		lebanon: "レバノン",
		lesotho: "レソト",
		liberia: "リベリア",
		libya: "リビア",
		madagascar: "マダガスカル",
		malawi: "マラウイ",
		maldives: "モルディブ",
		mali: "マリ",
		mauritania: "モーリタニア",
		mauritius: "モーリシャス",
		mozambique: "モザンビーク",
		namibia: "ナミビア",
		nicaragua: "ニカラグア",
		republic_of_Niger: "ニジェール",
		north_Korea: "朝鲜",
		reunion: "レユニオン",
		san_Marino: "サンマリノ",
		senegal: "セネガル",
		sierra_Leone: "シエラレオネ",
		somalia: "ソマリア",
		sudan: "スーダン",
		suriname: "スリナム",
		eswatini: "スワジランド",
		syria: "シリア",
		togo: "トーゴ",
		tonga: "トンガ",
		tunisia: "チュニジア",
		united_States_Virgin_Islands: "アメリカ領ヴァージン諸島",
		uganda: "ウガンダ",
		uruguay: "ウルグアイ",
		vatican: "バチカン",
		yemen: "イエメン",
		yugoslavia: "ユーゴスラビア",
		zambia: "ザンビア",
		zimbabwe: "ジンバブエ",
		china: "中国",
	}
};
